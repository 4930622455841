import { createTheme } from '@mui/material/styles';
import borders from '@styles/borders.module.scss';
import colors from '@styles/colors.module.scss';
import indents from '@styles/indents.module.scss';
import fontWeights from '@styles/font-weights.module.scss';
import typography from '@styles/typography.module.scss';
import shadows from '@styles/shadows.module.scss';

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.semiBold,
    fontWeightBold: fontWeights.bold,
    h1: {
      fontSize: typography.h1FontSize,
      lineHeight: typography.h1LineHeight,
      fontWeight: fontWeights.bold,
    },
    h2: {
      fontSize: typography.h2FontSize,
      lineHeight: typography.h2LineHeight,
      fontWeight: fontWeights.semiBold,
    },
    h3: {
      fontSize: typography.h3FontSize,
      lineHeight: typography.h3LineHeight,
      fontWeight: fontWeights.semiBold,
    },
    body1: {
      fontSize: typography.bodyBoldFontSize,
      lineHeight: typography.bodyBoldLineHeight,
      fontWeight: fontWeights.semiBold,
    },
    body2: {
      fontSize: typography.bodyFontSize,
      lineHeight: typography.bodyLineHeight,
      fontWeight: fontWeights.regular,
    },
    caption: {
      fontSize: typography.captionFontSize,
      lineHeight: typography.captionLineHeight,
      fontWeight: fontWeights.regular,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Inter, sans-serif',
          color: colors.black,
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: `${indents.xxs} ${indents.xs}`,
          textTransform: 'none',
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.semiBold,
          borderRadius: borders.radiusS,
          boxShadow: 'none',
          '&:disabled': {
            background: colors.greyLight,
            color: colors.grey,
          },
          '& svg': {
            fontSize: '1rem',
          },
          ...(ownerState.variant === 'contained' && {
            backgroundColor: colors.red,
            color: colors.white,
            '&:hover, &:active': {
              background: colors.dark,
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            paddingTop: '6px',
            paddingBottom: '6px',
            color: colors.red,
            border: `2px solid ${colors.red}`,
            '&:hover, &:active': {
              color: colors.black,
              border: `2px solid ${colors.black}`,
              background: 'transparent',
            },
          }),
          ...(ownerState.variant === 'text' && {
            color: colors.red,
            '&:hover, &:active': {
              color: colors.dark,
            },
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '6px',
          borderRadius: borders.radiusS,
          border: `2px solid ${colors.red}`,
          color: colors.red,
          transition: '.3s',
          backgroundColor: colors.white,
          '&:hover': {
            background: colors.white,
            color: colors.black,
            border: `2px solid ${colors.black}`,
          },
          '&:disabled': {
            color: colors.black,
            border: `2px solid ${colors.greyLight}`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 40,
          fontFamily: 'Inter, sans-serif',
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
          padding: `${indents.xxs} ${indents.xs}`,
        },
        input: {
          padding: 0,
          height: 'auto',
          '&::placeholder': {
            color: colors.grey,
            opacity: 1,
          },
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: `${indents.xxs} ${indents.xs}`,
          borderColor: colors.greyLight,
          outline: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            transition: '0.124s ease-in',
            transitionProperty: 'border-color',
            borderColor: colors.greyLight,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.dark,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.black,
            borderWidth: 1,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.error,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.background,
            color: colors.grey,
            '& .MuiInputAdornment-root': {
              color: colors.grey,
            },
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.greyLight,
          },
        },
        input: {
          padding: 0,
          height: 'auto',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, sans-serif',
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
          margin: 0,
          marginTop: '7px',
          padding: `4px ${indents.xs}`,
          borderRadius: borders.radiusM,
          width: 'fit-content',
          position: 'relative',
          border: `1px solid ${colors.greyLight}`,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-8px',
            left: '20px',
            display: 'block',
            width: 0,
            height: 0,
            borderWidth: '0 7px 8px 7px',
            borderColor: `transparent transparent ${colors.greyLight} transparent`,
            borderStyle: 'solid',
          },
          '&.Mui-error': {
            backgroundColor: colors.error,
            color: colors.white,
            border: `1px solid ${colors.error}`,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-8px',
              left: '20px',
              display: 'block',
              width: 0,
              height: 0,
              borderWidth: '0 7px 8px 7px',
              borderColor: `transparent transparent ${colors.error} transparent`,
              borderStyle: 'solid',
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
        positionStart: {
          marginRight: '10px',
        },
        positionEnd: {
          marginLeft: '10px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: colors.red,
          color: colors.white,
          height: 'auto',
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
          borderRadius: borders.radiusS,
          padding: '2px 6px',
        },
        deletable: {
          background: colors.lightRed,
          color: colors.black,
        },
        label: {
          padding: 0,
        },
        deleteIcon: {
          fontSize: typography.captionFontSize,
          margin: 0,
          marginLeft: indents.xxs,
          color: colors.grey,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          padding: 0,
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
          '&[data-popper-placement*="bottom"] > div': {
            marginTop: `${indents.xxs} !important`,

            '&.MuiTooltip-arrow': {
              top: 0,
            },
          },
        },
        tooltipPlacementBottom: {
          background: colors.lightRed,
          color: colors.black,
          borderRadius: borders.radiusM,
        },
        arrow: {
          color: colors.lightRed,
          width: '14px',
          height: '8px',
        },
        tooltip: {
          padding: `4px ${indents.xs}`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: shadows.alert,
          borderRadius: borders.radiusL,
          padding: `${indents.xs} ${indents.s}`,
          maxWidth: '264px',
          alignItems: 'center',
        },
        filledSuccess: {
          background: colors.success,
          color: colors.white,
          '& .MuiAlert-icon': {
            color: colors.success,
          },
        },
        filledInfo: {
          background: colors.dark,
          color: colors.white,
          '& .MuiAlert-icon': {
            color: colors.dark,
          },
        },
        filledError: {
          background: colors.error,
          color: colors.white,
          '& .MuiAlert-icon': {
            color: colors.error,
          },
        },
        icon: {
          opacity: 1,
          padding: 0,
          background: colors.white,
          minWidth: '20px',
          minHeight: '20px',
          maxWidth: '20px',
          maxHeight: '20px',
          borderRadius: '50%',
          marginRight: indents.s,
          fontSize: '15px',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            fontSize: '1rem',
          },
        },
        message: {
          fontWeight: fontWeights.regular,
          fontSize: typography.captionFontSize,
          lineHeight: typography.captionLineHeight,
          padding: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          '&:hover': {
            overflow: 'unset',
            display: 'block',
          },
        },
        action: {
          padding: 0,
          marginLeft: indents.l,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: 'auto',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: borders.m,
          color: colors.grey,
          '& .MuiSvgIcon-root': {
            fill: 'none',
          },
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            color: colors.red,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          color: colors.grey,
          '&.Mui-checked': {
            color: colors.red,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          display: 'flex',
          width: 32,
          height: 18,
          padding: 0,
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: colors.white,
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: colors.success,
            },
          },
        },
        thumb: {
          boxShadow: 'none',
          width: 14,
          height: 14,
          borderRadius: 7,
          transition: '.3s',
        },
        track: {
          boxSizing: 'border-box',
          borderRadius: 9,
          opacity: 1,
          backgroundColor: colors.red,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          position: 'absolute',
          zIndex: 1,
          backgroundColor: colors.white,
          opacity: 1,

          '&:last-child': {
            right: '20px',
          },

          '&.Mui-disabled': {
            opacity: 0,
          },
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: `${indents.xs} ${indents.m}`,
          borderLeft: `1px solid transparent`,
          borderRight: `1px solid transparent`,
          borderBottom: `1px solid ${colors.greyLight}`,
          textTransform: 'none',
          color: colors.dark,
          fontSize: typography.h2FontSize,
          lineHeight: typography.h2LineHeight,
          fontWeight: fontWeights.semiBold,
          transition: '.3s',

          '&:before': {
            content: 'none',
          },

          '&:hover': {
            color: colors.red,
          },

          '&:disabled': {
            color: colors.grey,
          },

          '&.Mui-selected': {
            color: colors.black,
            borderLeft: `1px solid ${colors.greyLight}`,
            borderRight: `1px solid ${colors.greyLight}`,
            borderBottomColor: 'transparent',
            borderRadius: `${borders.radiusS} ${borders.radiusS} 0 0`,
            overflow: 'visible',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: '0px',
              left: '-1px',
              width: 'calc(100% + 2px)',
              height: '5px',
              backgroundColor: colors.black,
              borderRadius: `${borders.radiusS} ${borders.radiusS} 0 0`,
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '32px',
          height: '32px',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            transition: 'background-color .3s',

            '&:hover': {
              backgroundColor: colors.background,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: indents.xs,
          borderBottomColor: colors.greyLight,
          overflow: 'hidden',
        },
        head: {
          padding: `4px ${indents.xs}`,
          fontSize: typography.bodyFontSize,
          lineHeight: typography.bodyLineHeight,
          fontWeight: fontWeights.regular,
          color: colors.grey,
        },
        body: {
          wordBreak: 'break-word',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backdrop,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '30px 40px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 40px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '30px 40px',
          justifyContent: 'flex-start',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
        label: {
          marginLeft: indents.xxs,
          fontWeight: fontWeights.regular,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: shadows.pageContent,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lightRed,
        },
        bar: {
          backgroundColor: colors.red,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          width: 24,
        },
        actions: {
          '& .MuiButtonBase-root': { border: 0, color: colors.black },
          '& svg': { width: 24 },
        },
      },
    },
  },
});
