import colors from '@styles/colors.module.scss';
import { PaymentCandidateProcessStatus, PaymentStatus } from '@area/payments/types';
import { Icons } from '@components/Icon/types';
import { Option } from '@components/Select/types';

export const DEFAULT_PAYMENTS_SIZE = 24;

export const DEFAULT_PAYMENT_CANDIDATES_SIZE = 10;

export const PaymentStatusColors: Record<PaymentStatus, string> = {
  [PaymentStatus.paused]: colors.lightRed,
  [PaymentStatus.processingComplete]: colors.success,
  [PaymentStatus.processing]: colors.greyLight,
  [PaymentStatus.approved]: colors.blue,
};

export const PaymentCandidateProcessStatusName: Record<PaymentCandidateProcessStatus, string> = {
  [PaymentCandidateProcessStatus.deleted]: 'Удален',
  [PaymentCandidateProcessStatus.error]: 'Обработан с ошибкой',
  [PaymentCandidateProcessStatus.rejected]: 'Не соответствует',
  [PaymentCandidateProcessStatus.approved]: 'Соответствует',
};

export const PaymentCandidateProcessStatusColors: Record<PaymentCandidateProcessStatus, string> = {
  [PaymentCandidateProcessStatus.deleted]: colors.success,
  [PaymentCandidateProcessStatus.error]: colors.error,
  [PaymentCandidateProcessStatus.rejected]: colors.grey,
  [PaymentCandidateProcessStatus.approved]: colors.black,
};

export const ChangeStatusButtonTitles: Record<PaymentStatus, string> = {
  [PaymentStatus.paused]: 'Продолжить',
  [PaymentStatus.processingComplete]: 'Подтвердить',
  [PaymentStatus.processing]: 'Остановить',
  [PaymentStatus.approved]: 'Подтверждено',
};

export const ChangeStatusButtonIcons: Record<PaymentStatus, keyof Icons> = {
  [PaymentStatus.paused]: 'play',
  [PaymentStatus.processingComplete]: 'check',
  [PaymentStatus.processing]: 'stop',
  [PaymentStatus.approved]: 'check',
};

export const PaymentCandidateProcessStatusOptions: Option<PaymentCandidateProcessStatus>[] = [
  {
    value: PaymentCandidateProcessStatus.deleted,
    label: PaymentCandidateProcessStatusName[PaymentCandidateProcessStatus.deleted],
  },
  {
    value: PaymentCandidateProcessStatus.error,
    label: PaymentCandidateProcessStatusName[PaymentCandidateProcessStatus.error],
  },
  {
    value: PaymentCandidateProcessStatus.rejected,
    label: PaymentCandidateProcessStatusName[PaymentCandidateProcessStatus.rejected],
  },
  {
    value: PaymentCandidateProcessStatus.approved,
    label: PaymentCandidateProcessStatusName[PaymentCandidateProcessStatus.approved],
  },
];
