import { useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { useParams } from 'react-router';
import { useGetTicketById } from '@area/tickets/hooks/useGetTicketById';
import { useAnswerTicket } from '@area/tickets/hooks/useAnswerTicket';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { TicketMainInfo } from '../TicketMainInfo';
import { TicketDetails } from '../TicketDetails';
import styles from './styles.module.scss';
import { TValues } from './validationSchema';
import { Form } from './Form';

export const TicketData = () => {
  const { id } = useParams();
  const { data, refetch } = useGetTicketById(id!);
  const { answerTicket, isLoading, isSuccess } = useAnswerTicket();
  const { handlePushItem } = useNotifications();

  const onSubmit = async (formData: TValues) => {
    await answerTicket({ id: id!, ticketAnswerRequest: { answerText: formData.answerText! } });
    refetch();
  };

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Ответ успешно отправлен' });
    }
  }, [isSuccess]);

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Box className={styles.root} sx={{ backgroundColor: colors.white }} width="100%" borderRadius={1} p="20px">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <TicketMainInfo data={data} />
      </Box>
      <TicketDetails data={data} />
      {!data.answerText && <Form onSubmit={onSubmit} defaultValues={{ answerText: data.answerText }} />}
      {isLoading && <LinearProgress className={styles.loadingIndicator} />}
    </Box>
  );
};
