export const Routes = {
  main: '/',
  // login
  login: 'login',
  otp: 'otp',
  confirm: 'confirm',
  onboardingChangePassword: 'strict-change-password',
  // Управление ИС
  users: 'users',
  translations: 'translations',
  settings: 'settings',
  scholarships: 'scholarships',
  nsiLogs: 'nsi-logs',
  // Контент
  publications: 'publications',
  events: 'events',
  informationPages: 'information-pages',
  notifications: 'notifications',
  email: 'email',
  push: 'push',
  // Участники
  students: 'students',
  // common
  newItem: 'new',
  id: ':id',
  profile: 'profile',
  // Выплаты
  payments: 'payments',
  paymentsType: ':paymentType',
  // Заявки
  applications: 'applications',
  applicationType: ':application_type',
  partners: 'partners',
  // Обращения
  tickets: 'tickets',
} as const;

export type RoutesKeys = keyof typeof Routes;
export type KampusRoute = (typeof Routes)[RoutesKeys];
