import { forwardRef, useRef, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Tooltip, ThemeProvider } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateService } from '@services/DateService';
import { DateRangePickerProps } from './types';
import { innerTheme } from './theme';

import { getSlots, getSlotProps } from './Slots';

export const DateRangePicker = forwardRef<unknown, DateRangePickerProps>((props, _) => {
  const { value, onChange } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [datesPicked, setDatesPicked] = useState(0);

  const textFieldValue = useMemo(() => {
    const startDate = value?.startDate ? dayjs(value?.startDate).format(DateService.dateFormats.dateField) : '';
    const endDate = value?.endDate ? dayjs(value?.endDate).format(DateService.dateFormats.dateField) : '';
    if (!value?.startDate && !value?.endDate) {
      return '';
    }
    if (!value?.startDate && value?.endDate) {
      return `- ${endDate}`;
    }
    if (value?.startDate && !value?.endDate) {
      return `${startDate} - `;
    }
    return `${startDate} - ${endDate}`;
  }, [value?.startDate, value?.endDate]);

  const slots = getSlots({
    ...props,
    textFieldProps: { ...props.textFieldProps, value: textFieldValue },
  });
  const slotProps = getSlotProps(wrapperRef, {
    startDate: value?.startDate,
    endDate: value?.endDate,
  } as any);

  return (
    <ThemeProvider theme={innerTheme}>
      <Tooltip open={Boolean(props.error)} title={props.error} arrow placement="bottom-start">
        <div ref={wrapperRef}>
          <MuiDatePicker
            minDate={Boolean(value?.startDate) && !Boolean(value?.endDate) ? dayjs(value?.startDate) : undefined}
            format="DD-MM-YYYY"
            dayOfWeekFormatter={(day: string) => day}
            {...props}
            closeOnSelect={false}
            onChange={(date: any) => {
              setDatesPicked(datesPicked + 1);

              if (datesPicked % 2 !== 0) {
                onChange?.({ startDate: value?.startDate, endDate: date });
              } else {
                onChange?.({ startDate: date, endDate: undefined });
              }
            }}
            value={props.value || null}
            slots={slots}
            slotProps={slotProps}
          />
        </div>
      </Tooltip>
    </ThemeProvider>
  );
});
