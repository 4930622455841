import { useCallback, useState } from 'react';
import axios, { AxiosHeaders } from 'axios';
import { getLocalStorageObject } from '@area/localstorage/localStorage';
import { LocalStorageKeys } from '@area/localstorage/constants';
import { defaultLang } from '@area/user/constants/defaultLang';
import { useNotifications } from '@area/notifications/hooks/useNotifications';

const getFilename = (headers: AxiosHeaders): string | undefined => {
  const contentDisposition = headers.get('content-disposition') as string;
  const encodedFileName = contentDisposition?.split('filename=')?.[1]?.split(';')?.[0]?.trim().replaceAll(/['"]/gi, '');

  try {
    return decodeURI(encodedFileName);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return encodedFileName;
  }
};

const createDownloadLink = (blob: Blob, filename: string) => {
  const href = URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = filename;
  anchorElement.click();

  URL.revokeObjectURL(href);
};

export const useDownloadFile = (url: string, fallbackFilename = 'unknown') => {
  const { handlePushItem } = useNotifications();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>();

  const download = useCallback(async () => {
    const headers = new AxiosHeaders();
    const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
    const userLang = getLocalStorageObject(LocalStorageKeys.UserLang) ?? defaultLang;

    if (!token) {
      return;
    }

    setIsDownloading(true);

    try {
      setIsSuccess(undefined);
      setIsError(undefined);
      headers.set('Authorization', `Bearer ${token.accessToken}`);
      headers.set('accept-language', userLang);

      const res = await axios.get(url, { responseType: 'blob', headers });

      createDownloadLink(res.data, getFilename(res.headers as AxiosHeaders) ?? fallbackFilename);
      setIsSuccess(true);
    } catch (e) {
      handlePushItem({ severity: 'error', text: 'Не удалось загрузить отчет.' });
      setIsError(true);
    } finally {
      setIsDownloading(false);
    }
  }, [fallbackFilename, handlePushItem, url]);

  return {
    isDownloading,
    download,
    isSuccess,
    isError,
  };
};
