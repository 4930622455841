import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import styles from './styles.module.scss';

export const LoadingSpinner: FC = () => {
  return (
    <Box className={styles.spinnerWrapper}>
      <CircularProgress className={styles.spinner} />
    </Box>
  );
};
