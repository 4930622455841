import { useCallback, useEffect, useMemo, useRef } from 'react';
import { UserFilterDto, UserResponse } from '@api/admin/adminGeneratedApi';
import { useQueryParams } from '@hooks/useQueryParams';
import { useUsersControllerFilterUsersMutation } from '@api/admin/adminApi';

export const DEFAULT_USERS_SIZE = 10;

const getValuesFromParams = (params: { [key: string]: string }): UserFilterDto => {
  const role = params.role ? decodeURIComponent(params.role).split(',') : undefined;

  return {
    search: params.search,
    role: role ? role.map((r) => Number(r) as any) : [],
    isActive: params.isActive ? params.isActive === 'true' : undefined,
  };
};

export const useFilterUsers = () => {
  const isRefetch = useRef<boolean>(true);
  const localUsers = useRef<UserResponse[]>([]);
  const { params } = useQueryParams();
  const [fetch, { data, isLoading, isSuccess, isError, originalArgs }] = useUsersControllerFilterUsersMutation();

  const users = useMemo(() => {
    if (!data?.data) {
      return localUsers.current;
    }

    if (isRefetch.current) {
      isRefetch.current = false;
      localUsers.current = [];
    }

    localUsers.current = [...localUsers.current, ...data?.data];

    return localUsers.current;
  }, [data?.data]);

  const fetchUsers = useCallback(
    (size = DEFAULT_USERS_SIZE) => {
      fetch({
        skip: isRefetch.current ? 0 : localUsers.current.length,
        size,
        userFilterDto: getValuesFromParams(params),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, params.search, params.isActive, params.role],
  );

  const refetchUsers = useCallback(() => {
    isRefetch.current = true;
    fetchUsers(originalArgs?.skip! + originalArgs?.size!);
  }, [fetchUsers, originalArgs]);

  useEffect(() => {
    isRefetch.current = true;
    fetchUsers();
  }, [fetchUsers]);

  return {
    users,
    isLoading: !users.length && isLoading,
    isFetched: !isRefetch.current && (isError || isSuccess),
    hasMore: (data?.paginate?.totalCount ?? 0) > localUsers.current.length,
    fetchUsers,
    refetchUsers,
  };
};
