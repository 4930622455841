import colors from '@styles/colors.module.scss';
import { OrderStatus, OrderType } from '@area/applications/types';
import { getApplicationType } from '@area/applications/utils/getApplicationType';
import { getStatusText } from '@area/applications/utils/getStatusText';

export const applicationStatusColors: { [key in OrderStatus]: string } = {
  [OrderStatus.new]: colors.greyLight,
  [OrderStatus.processing]: colors.greyLight,
  [OrderStatus.paused]: colors.lightRed,
  [OrderStatus.success]: colors.success,
  [OrderStatus.error]: colors.error,
};

export const applicationStatusTextColors: { [key in OrderStatus]: string } = {
  [OrderStatus.new]: colors.black,
  [OrderStatus.processing]: colors.black,
  [OrderStatus.paused]: colors.black,
  [OrderStatus.success]: colors.white,
  [OrderStatus.error]: colors.white,
};

export const typeOptions = [
  { label: getApplicationType(OrderType.esk_card), value: OrderType.esk_card },
  { label: getApplicationType(OrderType.extra_grant), value: OrderType.extra_grant },
  { label: getApplicationType(OrderType.credit_grant), value: OrderType.credit_grant },
];

export const statusOptions = [
  { label: getStatusText(OrderStatus.new), value: String(OrderStatus.new) },
  { label: getStatusText(OrderStatus.processing), value: String(OrderStatus.processing) },
  { label: getStatusText(OrderStatus.paused), value: String(OrderStatus.paused) },
  { label: getStatusText(OrderStatus.success), value: String(OrderStatus.success) },
  { label: getStatusText(OrderStatus.error), value: String(OrderStatus.error) },
];

export const DEFAULT_APPLICATIONS_SIZE = 10;
