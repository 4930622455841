import { FC, useState, useEffect } from 'react';
import { Collapse, ListItemButton, ThemeProvider, Tooltip, Typography } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import cn from 'classnames';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { useNavigate } from 'react-router';
import { useCurrentUserRoles } from '@area/user/hooks/useCurrentUserRoles';
import { Chapter as ChapterType, Link as ChapterLink } from './types';

import styles from './styles.module.scss';
import { innerTheme } from './theme';
import { ChaptersList } from './ChaptersList';

type ChapterProps = ChapterType & {
  openSidebar: boolean;
  toggleDrawer: () => void;
};

const isActive = (pathname: string, link?: string | ChapterLink[]): boolean => {
  if (!link) return false;

  if (Array.isArray(link)) {
    return link.some(({ href }) => matchPath({ path: href, end: false }, pathname));
  }

  return !!matchPath({ path: link, end: false }, pathname);
};

export const Chapter: FC<ChapterProps> = ({
  iconName,
  title,
  roles,
  disabled,
  links,
  link,
  openSidebar,
  toggleDrawer,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const active = isActive(pathname, link ?? links);
  const [open, setOpen] = useState(active && openSidebar);
  const { hasRole } = useCurrentUserRoles();

  const toggleChapter = () => {
    if (!links && !link) {
      return;
    }

    if (link) {
      return navigate(link);
    }

    if (openSidebar) {
      setOpen(!open);
      return;
    }

    toggleDrawer();

    if (active) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!openSidebar) {
      setOpen(false);
    }
  }, [openSidebar]);

  if (roles && !hasRole(roles)) {
    return null;
  }

  return (
    <>
      <ThemeProvider theme={innerTheme}>
        <Tooltip
          placement="right-start"
          className={styles.tooltip}
          title={!openSidebar && links && <ChaptersList links={links} isTooltip />}>
          <ListItemButton
            disableRipple
            onClick={toggleChapter}
            className={cn(styles.chapterTitleWrapper, { active: !open && active, openSidebar })}
            disabled={disabled}>
            <Icon
              name={iconName}
              htmlColor={colors.almostWhite}
              className={cn(styles.chapterTitleIcon, { openSidebar })}
            />
            <Typography variant="body1" className={styles.chapterTitle}>
              {title}
            </Typography>
            <Icon name="chevronDown" htmlColor={colors.grey} className={cn(styles.chevronIcon, { open })} />
          </ListItemButton>
        </Tooltip>
      </ThemeProvider>
      {links && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ChaptersList links={links} />
        </Collapse>
      )}
    </>
  );
};
