import { useParticipantsHumansControllerGetHumanQuery } from '@api/admin/adminGeneratedApi';
import { Grid } from '@mui/material';
import { usePageTitle } from '@hooks/usePageTitle';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { StudentTabs } from '@area/student/components/StudentTabs';
import { StudentNameEcp } from '@area/student/components/StudentNameEcp';
import indents from '@styles/indents.module.scss';
import { useParams } from 'react-router';

export const StudentPage = () => {
  usePageTitle('Данные участника'); // todo: заменит на имя участника?
  const { id } = useParams();
  const { data: humanResponse } = useParticipantsHumansControllerGetHumanQuery({
    id: id as string,
  });

  if (!humanResponse) {
    return null;
  }

  const name = [humanResponse.firstName, humanResponse.middleName, humanResponse.lastName].filter((w) => !!w).join(' ');

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item container flexDirection="column" gap={indents.xxs}>
        <Header
          title="Данные участника"
          backBtnTitle="Назад к списку участников"
          backBtnRoute={buildRoutePath(Routes.students, { absolute: true })}
        />
        <StudentNameEcp name={name ?? '—'} />
      </Grid>
      <Grid sx={{ width: '100%' }} item>
        <StudentTabs human={humanResponse} />
      </Grid>
    </Grid>
  );
};
