import { Filter } from '@components/Filter';
import { FC } from 'react';
import colors from '@styles/colors.module.scss';
import { Icon } from '@components/Icon';
import { FilterFormValues } from '@area/studentsPage/defaultValues';
import { useGetEduOrgs } from '@area/payments/hooks/useGetEduOrgs';
import { LoadingSpinner } from '@components/LoadingSpinner';
import styles from './styles.module.scss';

interface Props {
  onChange: (values: FilterFormValues) => void;
}

export const StudentsFilter: FC<Props> = ({ onChange }) => {
  const { data: eduOrgs, isLoading } = useGetEduOrgs();
  const eduOrgsOptions = eduOrgs?.map(({ id, nameShort }) => ({ label: nameShort, value: id }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Filter<FilterFormValues>
      onChange={onChange}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '340px', background: colors.white },
        },
        stretchable: true,
      }}
      trackedFields={[
        {
          type: 'select',
          name: 'status',
          props: {
            placeholder: 'Статус',
            icon: <Icon name="filter" />,
            options: [
              {
                value: 'active',
                label: 'Активный',
              },
              {
                value: 'notActive',
                label: 'Неактивный',
              },
              {
                value: 'activeStudent',
                label: 'Активный студент',
              },
              {
                value: 'activeNotStudent',
                label: 'Активный не студент',
              },
            ],
            sx: { width: '280px', background: colors.white },
          },
        },
        // {
        //   type: 'select',
        //   name: 'type',
        //   props: {
        //     placeholder: 'Студент / Преподаватель',
        //     sx: { width: '280px', background: colors.white },
        //     icon: <Icon name="type" />,
        //     options: [
        //       { label: 'Студент', value: 'student' },
        //       { label: 'Преподаватель', value: 'teacher' },
        //     ],
        //   },
        // },
        // {
        //   type: 'select',
        //   name: 'programType',
        //   props: {
        //     placeholder: 'Тип программы',
        //     sx: { width: '280px', background: colors.white },
        //     icon: <Icon name="rubric" />,
        //     options: mockOptions,
        //     multiple: true,
        //   },
        // },
        {
          type: 'datepicker',
          name: 'registrationDateFrom',
          props: {
            customLabel: <span className={styles.customLabel}>Период регистрации:</span>,
            placeholder: 'Дата от',
            textFieldProps: {
              placeholder: 'Дата от',
              sx: { width: '160px', background: colors.white },
            },
          },
        },
        {
          type: 'datepicker',
          name: 'registrationDateTo',
          props: {
            placeholder: 'Дата до',
            textFieldProps: { placeholder: 'Дата до', sx: { width: '160px', background: colors.white } },
          },
        },
        {
          type: 'select',
          name: 'unconfirmedByEduOrgs',
          props: {
            multiple: true,
            options: eduOrgsOptions,
            placeholder: 'Неподтвержденные по ВУЗу',
            sx: { minWidth: '300px', background: colors.white },
          },
        },
        {
          type: 'select',
          name: 'eduOrgs',
          props: {
            multiple: true,
            options: eduOrgsOptions,
            placeholder: 'ВУЗ',
            sx: { minWidth: '300px', background: colors.white },
          },
        },
      ]}
    />
  );
};
