import { useMemo, useEffect, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { Form } from '@area/publications/components/PublicationForm/Form';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { useUpdatePublication } from '@area/publications/hooks/useUpdatePublication';
import { useGetPublicationById } from '@area/publications/hooks/useGetPublicationById';
import {
  FormPublicationValues,
  defaultPublicationValues,
  mockPublicationTypes,
} from '@area/publications/defaultValues';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { serialize, deserialize } from '@area/redactor/components/Wysiwyg';
import { TypeService } from '@services/TypeService';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { useParams, useNavigate } from 'react-router';
import { getMetaTagsFromValues } from '@area/redactor/components/MetaTags/getMetaTagsFromValues';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';
import styles from './style.module.scss';

export const EditPublicationPage = () => {
  usePageTitle('Публикация');
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isError, isLoading } = useGetPublicationById(id!);
  const {
    updatePublication,
    resultOfUpdate: { isSuccess, isLoading: isLoadingForm, error: updateError },
  } = useUpdatePublication(id!);
  const { handlePushItem } = useNotifications();

  const { uploadMediaFiles, uploadError, deleteError } = useUploadMediaFiles();

  const onSubmit = useCallback(
    (values: FormPublicationValues) => {
      updatePublication({ ...values, editorContent: serialize(values.editorContent as CustomDescendant[]) });
      uploadMediaFiles({
        subjectId: id as string,
        mainImage: values.mainImage,
        snippetPhoto: values.snippetPhoto,
        photoGallery: values.photoGallery,
      });
    },
    [id, updatePublication, uploadMediaFiles],
  );

  const defaultValues = useMemo(() => {
    if (data) {
      return {
        ...data,
        startDate: dayjs(data.startDate),
        newsTagIds: data.tags?.map(({ id, title }) => ({ value: id, label: title })),
        authorIds: data.authors?.map(({ id, name }) => ({ value: id, label: name })),
        type: mockPublicationTypes.find((type) => type.value === data.type),
        editorContent: TypeService.isString(data.editorContent!)
          ? deserialize(data.editorContent!)
          : defaultPublicationValues.editorContent,
        metaTags: getMetaTagsFromValues(data.metaTags),
        embedded: data.embedded || '',
      };
    }

    return {
      ...defaultPublicationValues,
      editorContent: TypeService.isString(defaultPublicationValues.editorContent)
        ? deserialize(defaultPublicationValues.editorContent)
        : defaultPublicationValues.editorContent,
      metaTags: getMetaTagsFromValues(),
    };
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Публикация обновлена' });
      navigate(buildRoutePath(Routes.publications, { absolute: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (updateError && 'message' in updateError) {
      handlePushItem({ severity: 'error', text: updateError.message });
    }

    if (uploadError && 'message' in uploadError) {
      handlePushItem({ severity: 'error', text: uploadError.message });
    }

    if (deleteError && 'message' in deleteError) {
      handlePushItem({ severity: 'error', text: deleteError.message });
    }
  }, [uploadError, deleteError, updateError, handlePushItem]);

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Публикация"
          backBtnTitle="Назад к списку публикаций"
          backBtnRoute={buildRoutePath(Routes.publications, { absolute: true })}
        />
      </Grid>
      {isLoading && <LoadingSpinner />}
      {isError && <Typography>Ошибка загрузки публикации</Typography>}
      {!isLoading && !isError && (
        <Form<FormPublicationValues> defaultValues={defaultValues} onSubmit={onSubmit} isLoading={isLoadingForm} />
      )}
    </Grid>
  );
};
