import { useNotificationsControllerGetByIdQuery } from '@api/admin/adminGeneratedApi';
import { EditEmailForm } from '@area/notificationsPage/components/EditEmailForm';
import { EmailFormData } from '@area/notificationsPage/validation/validationSchemaEmail';
import { deserialize, serialize } from '@area/redactor/components/Wysiwyg';
import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Header } from '@layouts/Base/components/Header';
import { usePageTitle } from '@hooks/usePageTitle';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import {
  useNotificationsControllerUpdateMutation,
  useNotificationsControllerSendEmailMutation,
  useNotificationsControllerDeleteMutation,
} from '@api/admin/adminApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { Option } from '@components/Select/types';
import { ConfirmModal } from '@pages/EditEmailPage/ConfirmModal';
import { useState } from 'react';
import styles from './style.module.scss';

const createOptionByStr = (value: string): Option => ({
  label: value,
  value,
});

type ConfirmAction = 'send' | 'delete';

export const EditEmailPage = () => {
  usePageTitle('Email-рассылка');
  const { id } = useParams();
  const notificationsHelpers = useNotifications();
  const [confirmAction, setConfirmAction] = useState<ConfirmAction | null>(null);
  const navigate = useNavigate();

  const { data: notification, refetch } = useNotificationsControllerGetByIdQuery({
    id: id ?? '',
  });

  const defaultValues: EmailFormData = {
    title: notification?.title ?? '',
    text: notification?.text ? deserialize(notification?.text) : [{ type: 'paragraph', children: [{ text: '' }] }],
    from: notification?.sender ? createOptionByStr(notification.sender) : null,
    targets: notification?.targets?.join(',') ?? '',
  };

  const [updateNotification, { isLoading }] = useNotificationsControllerUpdateMutation();
  const [sendEmailController, { isLoading: isLoadingSend }] = useNotificationsControllerSendEmailMutation();
  const [deleteEmailController, { isLoading: isLoadingDelete }] = useNotificationsControllerDeleteMutation();

  const onEditEmail: SubmitHandler<EmailFormData> = async (formData) => {
    await updateNotification({
      id: id ?? '',
      notificationsRequest: {
        type: 1,
        sendAt: undefined,
        title: formData.title,
        sender: formData.from?.value,
        text: serialize(formData.text),
        targets: formData.targets.split(','),
      },
    }).unwrap();
    await refetch();
    notificationsHelpers.handlePushItem({
      text: 'Рассылка обновлена',
      severity: 'success',
    });
  };

  const handleSendEmail = () => {
    setConfirmAction('send');
  };
  const handleDelete = () => {
    setConfirmAction('delete');
  };

  const apply = async () => {
    if (confirmAction === 'send') {
      setConfirmAction(null);
      await sendEmailController({ id: id! }).unwrap();
      await refetch();
      notificationsHelpers.handlePushItem({
        text: 'Успешно поставлено в очередь на отправку',
        severity: 'success',
      });
    }

    if (confirmAction === 'delete') {
      setConfirmAction(null);
      await deleteEmailController({ id: id! }).unwrap();
      navigate(buildRoutePath(Routes.notifications, { absolute: true }));
      notificationsHelpers.handlePushItem({
        text: 'Рассылка удалена',
        severity: 'success',
      });
    }
  };

  if (!notification) {
    return null;
  }

  const readonly =
    notification &&
    (notification?.status > 1 ||
      (!!notification?.sendAt && new Date(notification?.sendAt).getTime() <= new Date().getTime()));

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Email-рассылка"
          backBtnTitle="Назад к списку рассылок"
          backBtnRoute={buildRoutePath(Routes.notifications, { absolute: true })}
        />
      </Grid>
      <EditEmailForm
        onDelete={readonly ? undefined : handleDelete}
        onSendEmail={readonly ? undefined : handleSendEmail}
        readonly={readonly}
        onSubmit={onEditEmail}
        defaultValues={defaultValues}
        isLoading={isLoading || isLoadingSend || isLoadingDelete}
      />
      {!!confirmAction && (
        <ConfirmModal
          applyText={confirmAction === 'send' ? 'Отправить' : 'Удалить'}
          onApply={apply}
          onClose={() => setConfirmAction(null)}
          title={confirmAction === 'send' ? 'Подтверждение отправки' : 'Подтверждение удаления'}
        />
      )}
    </Grid>
  );
};
