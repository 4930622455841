import { adminGeneratedApi } from './adminGeneratedApi';
import { ApiTags } from './tags';


export const adminApi = adminGeneratedApi.enhanceEndpoints({
  endpoints: {
    usersControllerGetUser: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    usersControllerUpdateUser: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    usersControllerUpdateUserRole: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    usersControllerUpdateUserActiveStatus: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetUser, id: arg.id }],
    },
    participantsHumansControllerGetHuman: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetParticipant, id: arg.id }],
    },
    participantsHumansControllerUndeleteHuman: {
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.GetParticipant, id: arg.id }],
    },
    usersControllerGetCurrentUser: {
      providesTags: [ApiTags.GetUserProfile],
    },
    usersControllerChangeCurrentUserPassword: {
      invalidatesTags: [ApiTags.GetUserProfile]
    },
    authorsControllerGetAuthors: {
      providesTags: [ApiTags.GetAuthors],
    },
    newsTagsControllerGetNewsTags: {
      providesTags: [ApiTags.GetPublicationTags],
    },
    eventsControllerGetEvent: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetEvent, id: arg.id }]
    },
    infoPagesControllerGetInfoPage: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetInfoPage, id: arg.id }]
    },
    newsControllerGetNews: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetNews, id: arg.id }]
    },
    authControllerRefresh: {
      invalidatesTags: [ApiTags.GetUserProfile],
    },
    authControllerLogin: {
      invalidatesTags: [ApiTags.GetUserProfile],
    },
    authorsControllerCreateAuthor: {
      invalidatesTags: [ApiTags.GetAuthors],
    },
    newsTagsControllerCreateNewsTag: {
      invalidatesTags: [ApiTags.GetPublicationTags],
    },
    newsTagsControllerDeleteNewsTag: {
      invalidatesTags: [ApiTags.GetPublicationTags],
    },
    newsControllerUpdateNews: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetNews, id: arg.id }];
      },
    },
    newsControllerDeleteNews: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetNews, id: arg.id }];
      },
    },
    eventsControllerUpdateEvent: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetEvent, id: arg.id }];
      },
    },
    eventsControllerDeleteEvent: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetEvent, id: arg.id }];
      },
    },
    infoPagesControllerUpdateInfoPage: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetInfoPage, id: arg.id }];
      },
    },
    infoPagesControllerDeleteInfoPage: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetInfoPage, id: arg.id }];
      },
    },
    filesControllerUploadFile: {
      invalidatesTags: (result, error, arg) => {
        return [
            { type: ApiTags.GetNews, id: arg.subjectId },
            { type: ApiTags.GetInfoPage, id: arg.subjectId },
            { type: ApiTags.GetEvent, id: arg.subjectId },
        ]
      }
    },
    paymentControllerFilterPayments: {
      invalidatesTags: [ApiTags.GetPayments]
    },
    paymentControllerCreatePayment: {
      invalidatesTags: [ApiTags.GetPayments]
    },
    partnerControllerGetPartner: {
      providesTags: (result, error, arg) => [{ type: ApiTags.GetPartner, id: arg.id }],
    },
    partnerControllerUpdatePartner: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: ApiTags.GetPartner, id: arg.id }];
      },
    },
    notificationsControllerFilter: {
      providesTags: [ApiTags.GetNotifications] as any,
    },
    notificationsControllerUpdate: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    notificationsControllerDelete: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    notificationsControllerSendEmail: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
    notificationsControllerCreate: {
      invalidatesTags: [ApiTags.GetNotifications],
    },
  },
});

export const {
  useAuthControllerLoginMutation,
  useAuthControllerLogin2FaMutation,
  useAuthControllerRefreshMutation,
  useMetricsControllerIndexQuery,
  useUsersControllerFilterUsersMutation,
  useUsersControllerGetUserQuery,
  useUsersControllerCreateUserMutation,
  useUsersControllerUpdateUserMutation,
  useUsersControllerUpdateUserRoleMutation,
  useUsersControllerUpdateUserActiveStatusMutation,
  useUsersControllerDeleteUserMutation,
  useUsersControllerGetCurrentUserQuery,
  useUsersControllerUpdateCurrentUserMutation,
  useUsersControllerChangeCurrentUserPasswordMutation,
  useUsersControllerResetPasswordMutation,
  useResourcesControllerLanguagesQuery,
  useResourcesControllerCreateOrUpdateLanguageMutation,
  useResourcesControllerGetTranslationsQuery,
  useResourcesControllerCreateOrUpdateTranslationMutation,
  useResourcesControllerUserRolesQuery,
  useSettingsControllerGetQuery,
  useSettingsControllerUpdateMutation,
  useNewsTagsControllerGetNewsTagsQuery,
  useNewsTagsControllerCreateNewsTagMutation,
  useNewsTagsControllerUpdateNewsTagMutation,
  useNewsTagsControllerDeleteNewsTagMutation,
  useAuthorsControllerGetAuthorsQuery,
  useAuthorsControllerCreateAuthorMutation,
  useAuthorsControllerUpdateAuthorMutation,
  useAuthorsControllerDeleteAuthorMutation,
  useNewsControllerFilterNewsMutation,
  useNewsControllerCreateNewsMutation,
  useNewsControllerGetNewsQuery,
  useNewsControllerUpdateNewsMutation,
  useNewsControllerDeleteNewsMutation,
  useNewsControllerGetNewsByUrlQuery,
  useEventsControllerFilterEventsMutation,
  useEventsControllerCreateEventMutation,
  useEventsControllerGetEventQuery,
  useEventsControllerUpdateEventMutation,
  useEventsControllerDeleteEventMutation,
  useEventsControllerGetEventBuUrlQuery,
  useInfoPagesControllerFilterInfoPagesMutation,
  useInfoPagesControllerCreateInfoPageMutation,
  useInfoPagesControllerGetInfoPageQuery,
  useInfoPagesControllerUpdateInfoPageMutation,
  useInfoPagesControllerDeleteInfoPageMutation,
  useInfoPagesControllerGetInfoPageBuUrlQuery,
  useFilesControllerUploadFileMutation,
  usePaymentControllerCreatePaymentMutation,
  usePaymentControllerFilterPaymentsMutation,
  usePaymentControllerGetPaymentQuery,
  usePaymentControllerChangeStatusMutation,
  usePaymentControllerDeletePaymentMutation,
  usePaymentControllerFilterCandidatesMutation,
  usePaymentControllerGetEducationalOrganizationsQuery,
  usePartnerControllerGetPartnerQuery,
  usePartnerControllerUpdatePartnerMutation,
  useNsiSyncControllerFilterSyncDataLogsMutation,
  useNsiSyncControllerGetSyncDataLogQuery,
  useNotificationsControllerFilterMutation,
  useNotificationsControllerUpdateMutation,
  useNotificationsControllerDeleteMutation,
  useNotificationsControllerCreateMutation,
  useNotificationsControllerSendEmailMutation,
  useParticipantsHumansControllerUndeleteHumanMutation,
} = adminApi;
