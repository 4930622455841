import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { PaymentMainInfo } from '@area/payments/components/PaymentMainInfo';
import { StatusHistoryTable } from '@area/payments/components/StatusHistoryTable';
import { PaymentsResultsTable } from '@area/payments/components/PaymentsResultsTable';
import { PaymentsMembers } from '@area/payments/components/PaymentsMembers';
import { PaymentsByUniversity } from '@area/payments/components/PaymentsByUniversity';
import { usePaymentControllerGetPaymentQuery } from '@api/admin/adminApi';
import { PaymentChangeStatusButton } from '@area/payments/components/PaymentChangeStatusButton';
import { PaymentStatus } from '@area/payments/types';
import colors from '@styles/colors.module.scss';
import { PaymentsByAllCandidates } from '@area/payments/components/PaymentsByAllCandidates';

export const PaymentData = () => {
  const { id } = useParams();
  const { data, isSuccess, isFetching, isLoading, refetch } = usePaymentControllerGetPaymentQuery({ id: id! });

  if (!isSuccess || !data) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: colors.white }} width="100%" borderRadius={1} p="20px">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <PaymentMainInfo
          createdAt={data.createdAt}
          startedBy={data.startedBy}
          number={data.number}
          status={data.status}
          period={data.period}
        />
        <PaymentChangeStatusButton status={data.status} loading={isLoading || isFetching} onSuccess={refetch} />
      </Box>
      <StatusHistoryTable createdAt={data.createdAt} startedBy={data.startedBy} statusChanges={data.statusChanges} />
      {(data.status === PaymentStatus.processingComplete || data.status === PaymentStatus.approved) && (
        <>
          <PaymentsResultsTable candidates={data.candidates} />
          <PaymentsMembers />
        </>
      )}
      {data.status === PaymentStatus.approved && data.type === 'extraGrant' && <PaymentsByUniversity />}
      {data.status === PaymentStatus.approved && data.type === 'creditGrant' && <PaymentsByAllCandidates />}
    </Box>
  );
};
