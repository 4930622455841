import { adminBaseApi as api } from "./adminBaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/login`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    authControllerRefresh: build.mutation<
      AuthControllerRefreshApiResponse,
      AuthControllerRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/refresh`,
        method: "POST",
        body: queryArg.refreshDto,
      }),
    }),
    authControllerLogin2Fa: build.mutation<
      AuthControllerLogin2FaApiResponse,
      AuthControllerLogin2FaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/login2fa`,
        method: "POST",
        body: queryArg.otpCreatorDto,
      }),
    }),
    metricsControllerIndex: build.query<
      MetricsControllerIndexApiResponse,
      MetricsControllerIndexApiArg
    >({
      query: () => ({ url: `/api/metrics` }),
    }),
    usersControllerFilterUsers: build.mutation<
      UsersControllerFilterUsersApiResponse,
      UsersControllerFilterUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/filter`,
        method: "POST",
        body: queryArg.userFilterDto,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    usersControllerGetCurrentUser: build.query<
      UsersControllerGetCurrentUserApiResponse,
      UsersControllerGetCurrentUserApiArg
    >({
      query: () => ({ url: `/api/users/current` }),
    }),
    usersControllerUpdateCurrentUser: build.mutation<
      UsersControllerUpdateCurrentUserApiResponse,
      UsersControllerUpdateCurrentUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current`,
        method: "PUT",
        body: queryArg.userUpdaterDto,
      }),
    }),
    usersControllerGetUser: build.query<
      UsersControllerGetUserApiResponse,
      UsersControllerGetUserApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/${queryArg.id}` }),
    }),
    usersControllerUpdateUser: build.mutation<
      UsersControllerUpdateUserApiResponse,
      UsersControllerUpdateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.userUpdaterDto,
      }),
    }),
    usersControllerDeleteUser: build.mutation<
      UsersControllerDeleteUserApiResponse,
      UsersControllerDeleteUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    usersControllerCreateUser: build.mutation<
      UsersControllerCreateUserApiResponse,
      UsersControllerCreateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users`,
        method: "POST",
        body: queryArg.userCreatorDto,
      }),
    }),
    usersControllerUpdateUserRole: build.mutation<
      UsersControllerUpdateUserRoleApiResponse,
      UsersControllerUpdateUserRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}/update-role`,
        method: "PUT",
        body: queryArg.userRoleUpdaterDto,
      }),
    }),
    usersControllerUpdateUserActiveStatus: build.mutation<
      UsersControllerUpdateUserActiveStatusApiResponse,
      UsersControllerUpdateUserActiveStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}/update-active-status`,
        method: "PUT",
        body: queryArg.userActiveStatusUpdaterDto,
      }),
    }),
    usersControllerChangeCurrentUserPassword: build.mutation<
      UsersControllerChangeCurrentUserPasswordApiResponse,
      UsersControllerChangeCurrentUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/change-password`,
        method: "PATCH",
        body: queryArg.userPasswordUpdaterDto,
      }),
    }),
    usersControllerResetPassword: build.mutation<
      UsersControllerResetPasswordApiResponse,
      UsersControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}/reset-password`,
        method: "PATCH",
      }),
    }),
    usersControllerUpdateCurrentUserPhoto: build.mutation<
      UsersControllerUpdateCurrentUserPhotoApiResponse,
      UsersControllerUpdateCurrentUserPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/photo`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    notificationsControllerFilter: build.mutation<
      NotificationsControllerFilterApiResponse,
      NotificationsControllerFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/filter`,
        method: "POST",
        body: queryArg.notificationsFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    notificationsControllerGetById: build.query<
      NotificationsControllerGetByIdApiResponse,
      NotificationsControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/notifications/${queryArg.id}` }),
    }),
    notificationsControllerUpdate: build.mutation<
      NotificationsControllerUpdateApiResponse,
      NotificationsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/${queryArg.id}`,
        method: "PUT",
        body: queryArg.notificationsRequest,
      }),
    }),
    notificationsControllerDelete: build.mutation<
      NotificationsControllerDeleteApiResponse,
      NotificationsControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    notificationsControllerCreate: build.mutation<
      NotificationsControllerCreateApiResponse,
      NotificationsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications`,
        method: "POST",
        body: queryArg.notificationsRequest,
      }),
    }),
    notificationsControllerSendEmail: build.mutation<
      NotificationsControllerSendEmailApiResponse,
      NotificationsControllerSendEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/${queryArg.id}/send`,
        method: "POST",
      }),
    }),
    settingsControllerGet: build.query<
      SettingsControllerGetApiResponse,
      SettingsControllerGetApiArg
    >({
      query: () => ({ url: `/api/settings` }),
    }),
    settingsControllerUpdate: build.mutation<
      SettingsControllerUpdateApiResponse,
      SettingsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/${queryArg.id}`,
        method: "PUT",
        body: queryArg.settingUpdaterDto,
      }),
    }),
    filesControllerGet: build.query<
      FilesControllerGetApiResponse,
      FilesControllerGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.bucket}/${queryArg.fileName}`,
      }),
    }),
    filesControllerDeleteFile: build.mutation<
      FilesControllerDeleteFileApiResponse,
      FilesControllerDeleteFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    filesControllerEditFileDescriptions: build.mutation<
      FilesControllerEditFileDescriptionsApiResponse,
      FilesControllerEditFileDescriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.fileId}`,
        method: "PATCH",
        body: queryArg.mediaFileEditDto,
      }),
    }),
    filesControllerUploadFile: build.mutation<
      FilesControllerUploadFileApiResponse,
      FilesControllerUploadFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.subjectId}`,
        method: "PUT",
        body: queryArg.mediaFileUpdaterDto,
      }),
    }),
    resourcesControllerLanguages: build.query<
      ResourcesControllerLanguagesApiResponse,
      ResourcesControllerLanguagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resources/languages`,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    resourcesControllerCreateOrUpdateLanguage: build.mutation<
      ResourcesControllerCreateOrUpdateLanguageApiResponse,
      ResourcesControllerCreateOrUpdateLanguageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resources/languages`,
        method: "POST",
        body: queryArg.languageRequest,
      }),
    }),
    resourcesControllerGetTranslations: build.query<
      ResourcesControllerGetTranslationsApiResponse,
      ResourcesControllerGetTranslationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resources/translations`,
        params: {
          languageId: queryArg.languageId,
          mnemonic: queryArg.mnemonic,
          skip: queryArg.skip,
          size: queryArg.size,
          asc: queryArg.asc,
        },
      }),
    }),
    resourcesControllerCreateOrUpdateTranslation: build.mutation<
      ResourcesControllerCreateOrUpdateTranslationApiResponse,
      ResourcesControllerCreateOrUpdateTranslationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/resources/translations`,
        method: "POST",
        body: queryArg.translationRequest,
      }),
    }),
    resourcesControllerUserRoles: build.query<
      ResourcesControllerUserRolesApiResponse,
      ResourcesControllerUserRolesApiArg
    >({
      query: () => ({ url: `/api/resources/user-roles` }),
    }),
    resourcesControllerTestI18: build.query<
      ResourcesControllerTestI18ApiResponse,
      ResourcesControllerTestI18ApiArg
    >({
      query: () => ({ url: `/api/resources/testi18` }),
    }),
    resourcesControllerGetEskIssuing: build.query<
      ResourcesControllerGetEskIssuingApiResponse,
      ResourcesControllerGetEskIssuingApiArg
    >({
      query: () => ({ url: `/api/resources/esk-issuing` }),
    }),
    resourcesControllerGetPublicSettings: build.query<
      ResourcesControllerGetPublicSettingsApiResponse,
      ResourcesControllerGetPublicSettingsApiArg
    >({
      query: () => ({ url: `/api/resources/public-settings` }),
    }),
    resourcesControllerServiceWorks: build.query<
      ResourcesControllerServiceWorksApiResponse,
      ResourcesControllerServiceWorksApiArg
    >({
      query: () => ({ url: `/api/resources/service-works` }),
    }),
    resourcesControllerGetEduOrgs: build.query<
      ResourcesControllerGetEduOrgsApiResponse,
      ResourcesControllerGetEduOrgsApiArg
    >({
      query: () => ({ url: `/api/resources/edu-orgs` }),
    }),
    newsTagsControllerGetNewsTags: build.query<
      NewsTagsControllerGetNewsTagsApiResponse,
      NewsTagsControllerGetNewsTagsApiArg
    >({
      query: () => ({ url: `/api/media/news-tags` }),
    }),
    newsTagsControllerCreateNewsTag: build.mutation<
      NewsTagsControllerCreateNewsTagApiResponse,
      NewsTagsControllerCreateNewsTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news-tags`,
        method: "POST",
        body: queryArg.newsTagRequest,
      }),
    }),
    newsTagsControllerUpdateNewsTag: build.mutation<
      NewsTagsControllerUpdateNewsTagApiResponse,
      NewsTagsControllerUpdateNewsTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news-tags/${queryArg.id}`,
        method: "PUT",
        body: queryArg.newsTagRequest,
      }),
    }),
    newsTagsControllerDeleteNewsTag: build.mutation<
      NewsTagsControllerDeleteNewsTagApiResponse,
      NewsTagsControllerDeleteNewsTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news-tags/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    authorsControllerGetAuthors: build.query<
      AuthorsControllerGetAuthorsApiResponse,
      AuthorsControllerGetAuthorsApiArg
    >({
      query: () => ({ url: `/api/media/authors` }),
    }),
    authorsControllerCreateAuthor: build.mutation<
      AuthorsControllerCreateAuthorApiResponse,
      AuthorsControllerCreateAuthorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/authors`,
        method: "POST",
        body: queryArg.authorRequest,
      }),
    }),
    authorsControllerUpdateAuthor: build.mutation<
      AuthorsControllerUpdateAuthorApiResponse,
      AuthorsControllerUpdateAuthorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/authors/${queryArg.id}`,
        method: "PUT",
        body: queryArg.authorRequest,
      }),
    }),
    authorsControllerDeleteAuthor: build.mutation<
      AuthorsControllerDeleteAuthorApiResponse,
      AuthorsControllerDeleteAuthorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/authors/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    newsControllerFilterNews: build.mutation<
      NewsControllerFilterNewsApiResponse,
      NewsControllerFilterNewsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news/filter`,
        method: "POST",
        body: queryArg.newsFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    newsControllerGetNews: build.query<
      NewsControllerGetNewsApiResponse,
      NewsControllerGetNewsApiArg
    >({
      query: (queryArg) => ({ url: `/api/media/news/${queryArg.id}` }),
    }),
    newsControllerUpdateNews: build.mutation<
      NewsControllerUpdateNewsApiResponse,
      NewsControllerUpdateNewsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news/${queryArg.id}`,
        method: "PUT",
        body: queryArg.newsRequest,
      }),
    }),
    newsControllerDeleteNews: build.mutation<
      NewsControllerDeleteNewsApiResponse,
      NewsControllerDeleteNewsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    newsControllerGetNewsByUrl: build.query<
      NewsControllerGetNewsByUrlApiResponse,
      NewsControllerGetNewsByUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/media/news/url/${queryArg.url}` }),
    }),
    newsControllerCreateNews: build.mutation<
      NewsControllerCreateNewsApiResponse,
      NewsControllerCreateNewsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/news`,
        method: "POST",
        body: queryArg.newsRequest,
      }),
    }),
    eventsControllerFilterEvents: build.mutation<
      EventsControllerFilterEventsApiResponse,
      EventsControllerFilterEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/events/filter`,
        method: "POST",
        body: queryArg.eventFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    eventsControllerGetEvent: build.query<
      EventsControllerGetEventApiResponse,
      EventsControllerGetEventApiArg
    >({
      query: (queryArg) => ({ url: `/api/media/events/${queryArg.id}` }),
    }),
    eventsControllerUpdateEvent: build.mutation<
      EventsControllerUpdateEventApiResponse,
      EventsControllerUpdateEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/events/${queryArg.id}`,
        method: "PUT",
        body: queryArg.eventRequest,
      }),
    }),
    eventsControllerDeleteEvent: build.mutation<
      EventsControllerDeleteEventApiResponse,
      EventsControllerDeleteEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/events/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    eventsControllerGetEventBuUrl: build.query<
      EventsControllerGetEventBuUrlApiResponse,
      EventsControllerGetEventBuUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/media/events/url/${queryArg.url}` }),
    }),
    eventsControllerCreateEvent: build.mutation<
      EventsControllerCreateEventApiResponse,
      EventsControllerCreateEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/events`,
        method: "POST",
        body: queryArg.eventRequest,
      }),
    }),
    infoPagesControllerFilterInfoPages: build.mutation<
      InfoPagesControllerFilterInfoPagesApiResponse,
      InfoPagesControllerFilterInfoPagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/info-pages/filter`,
        method: "POST",
        body: queryArg.infoPageFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    infoPagesControllerGetInfoPage: build.query<
      InfoPagesControllerGetInfoPageApiResponse,
      InfoPagesControllerGetInfoPageApiArg
    >({
      query: (queryArg) => ({ url: `/api/media/info-pages/${queryArg.id}` }),
    }),
    infoPagesControllerUpdateInfoPage: build.mutation<
      InfoPagesControllerUpdateInfoPageApiResponse,
      InfoPagesControllerUpdateInfoPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/info-pages/${queryArg.id}`,
        method: "PUT",
        body: queryArg.infoPageRequest,
      }),
    }),
    infoPagesControllerDeleteInfoPage: build.mutation<
      InfoPagesControllerDeleteInfoPageApiResponse,
      InfoPagesControllerDeleteInfoPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/info-pages/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    infoPagesControllerGetInfoPageBuUrl: build.query<
      InfoPagesControllerGetInfoPageBuUrlApiResponse,
      InfoPagesControllerGetInfoPageBuUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/info-pages/url/${queryArg.url}`,
      }),
    }),
    infoPagesControllerCreateInfoPage: build.mutation<
      InfoPagesControllerCreateInfoPageApiResponse,
      InfoPagesControllerCreateInfoPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/media/info-pages`,
        method: "POST",
        body: queryArg.infoPageRequest,
      }),
    }),
    participantsControllerGetCurrentHuman: build.query<
      ParticipantsControllerGetCurrentHumanApiResponse,
      ParticipantsControllerGetCurrentHumanApiArg
    >({
      query: () => ({ url: `/api/participants/current-human` }),
    }),
    participantsControllerPutCurrentHuman: build.mutation<
      ParticipantsControllerPutCurrentHumanApiResponse,
      ParticipantsControllerPutCurrentHumanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/current-human`,
        method: "PUT",
        body: queryArg.humanRequest,
      }),
    }),
    participantsControllerPutCurrentHumanIdentity: build.mutation<
      ParticipantsControllerPutCurrentHumanIdentityApiResponse,
      ParticipantsControllerPutCurrentHumanIdentityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/current-human/identity`,
        method: "PUT",
        body: queryArg.humanIdentityRequest,
      }),
    }),
    participantsControllerGetEskCard: build.query<
      ParticipantsControllerGetEskCardApiResponse,
      ParticipantsControllerGetEskCardApiArg
    >({
      query: () => ({ url: `/api/participants/esk-card` }),
    }),
    participantsControllerGetWorkPlan: build.query<
      ParticipantsControllerGetWorkPlanApiResponse,
      ParticipantsControllerGetWorkPlanApiArg
    >({
      query: () => ({ url: `/api/participants/work-plan` }),
    }),
    participantsControllerGetPayHistory: build.query<
      ParticipantsControllerGetPayHistoryApiResponse,
      ParticipantsControllerGetPayHistoryApiArg
    >({
      query: () => ({ url: `/api/participants/pay-history` }),
    }),
    participantsControllerAddHumanFcmToken: build.mutation<
      ParticipantsControllerAddHumanFcmTokenApiResponse,
      ParticipantsControllerAddHumanFcmTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/fcm-token`,
        method: "PUT",
        body: queryArg.humanFcmTokenRequest,
      }),
    }),
    participantsHumansControllerFilterHumans: build.mutation<
      ParticipantsHumansControllerFilterHumansApiResponse,
      ParticipantsHumansControllerFilterHumansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/filter`,
        method: "POST",
        body: queryArg.humansFilterRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    participantsHumansControllerGetHuman: build.query<
      ParticipantsHumansControllerGetHumanApiResponse,
      ParticipantsHumansControllerGetHumanApiArg
    >({
      query: (queryArg) => ({ url: `/api/participants/humans/${queryArg.id}` }),
    }),
    participantsHumansControllerGetHumanPrograms: build.query<
      ParticipantsHumansControllerGetHumanProgramsApiResponse,
      ParticipantsHumansControllerGetHumanProgramsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/${queryArg.id}/programs`,
      }),
    }),
    participantsHumansControllerGetHumanEskCard: build.query<
      ParticipantsHumansControllerGetHumanEskCardApiResponse,
      ParticipantsHumansControllerGetHumanEskCardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/${queryArg.id}/esk-card`,
      }),
    }),
    participantsHumansControllerGetHumanWorkPlan: build.query<
      ParticipantsHumansControllerGetHumanWorkPlanApiResponse,
      ParticipantsHumansControllerGetHumanWorkPlanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/${queryArg.id}/work-plan`,
        params: { period: queryArg.period },
      }),
    }),
    participantsHumansControllerGetHumanPayHistory: build.query<
      ParticipantsHumansControllerGetHumanPayHistoryApiResponse,
      ParticipantsHumansControllerGetHumanPayHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/${queryArg.id}/pay-history`,
      }),
    }),
    participantsHumansControllerGetHumanLogs: build.query<
      ParticipantsHumansControllerGetHumanLogsApiResponse,
      ParticipantsHumansControllerGetHumanLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/${queryArg.id}/logs`,
      }),
    }),
    participantsHumansControllerUndeleteHuman: build.mutation<
      ParticipantsHumansControllerUndeleteHumanApiResponse,
      ParticipantsHumansControllerUndeleteHumanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/humans/${queryArg.id}/undelete`,
        method: "POST",
      }),
    }),
    studentControllerStudentInfo: build.mutation<
      StudentControllerStudentInfoApiResponse,
      StudentControllerStudentInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/student/find/${queryArg.eduOrgId}`,
        method: "POST",
      }),
    }),
    studentControllerApplyStudent: build.mutation<
      StudentControllerApplyStudentApiResponse,
      StudentControllerApplyStudentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/student/apply/${queryArg.uuid}`,
        method: "POST",
      }),
    }),
    participantsApplicationsControllerCreateApplicationEskCard: build.mutation<
      ParticipantsApplicationsControllerCreateApplicationEskCardApiResponse,
      ParticipantsApplicationsControllerCreateApplicationEskCardApiArg
    >({
      query: () => ({
        url: `/api/participants/application/esk-card`,
        method: "POST",
      }),
    }),
    participantsApplicationsControllerCreateApplicationExtraGrant:
      build.mutation<
        ParticipantsApplicationsControllerCreateApplicationExtraGrantApiResponse,
        ParticipantsApplicationsControllerCreateApplicationExtraGrantApiArg
      >({
        query: () => ({
          url: `/api/participants/application/extra-grant`,
          method: "POST",
        }),
      }),
    participantsApplicationsControllerCreateApplicationCreditGrant:
      build.mutation<
        ParticipantsApplicationsControllerCreateApplicationCreditGrantApiResponse,
        ParticipantsApplicationsControllerCreateApplicationCreditGrantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/participants/application/credit-grant`,
          method: "POST",
          body: queryArg.creditApplicationRequest,
        }),
      }),
    participantsApplicationsControllerCancelApplication: build.mutation<
      ParticipantsApplicationsControllerCancelApplicationApiResponse,
      ParticipantsApplicationsControllerCancelApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/application/${queryArg.id}/cancel`,
        method: "POST",
      }),
    }),
    participantsApplicationsControllerGetHumanApplications: build.query<
      ParticipantsApplicationsControllerGetHumanApplicationsApiResponse,
      ParticipantsApplicationsControllerGetHumanApplicationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/application/my`,
        params: { type: queryArg["type"] },
      }),
    }),
    participantsTicketControllerCreateTicket: build.mutation<
      ParticipantsTicketControllerCreateTicketApiResponse,
      ParticipantsTicketControllerCreateTicketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/ticket/guest`,
        method: "POST",
        body: queryArg.participantTicketRequest,
      }),
    }),
    participantsTicketControllerCreateParticipantsTicket: build.mutation<
      ParticipantsTicketControllerCreateParticipantsTicketApiResponse,
      ParticipantsTicketControllerCreateParticipantsTicketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/participants/ticket`,
        method: "POST",
        body: queryArg.participantTicketRequest,
      }),
    }),
    participantsTicketControllerGetTickets: build.query<
      ParticipantsTicketControllerGetTicketsApiResponse,
      ParticipantsTicketControllerGetTicketsApiArg
    >({
      query: () => ({ url: `/api/participants/ticket/my` }),
    }),
    educationalOrganizationControllerGetEduOrgGrants: build.query<
      EducationalOrganizationControllerGetEduOrgGrantsApiResponse,
      EducationalOrganizationControllerGetEduOrgGrantsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/educational-organization/grants`,
        params: { search: queryArg.search },
      }),
    }),
    educationalOrganizationControllerSetEduOrgGrant: build.mutation<
      EducationalOrganizationControllerSetEduOrgGrantApiResponse,
      EducationalOrganizationControllerSetEduOrgGrantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/educational-organization/grants/${queryArg.eduId}`,
        method: "PUT",
        body: queryArg.educationalOrganizationGrantRequest,
      }),
    }),
    paymentControllerGetAllEduOrgs: build.query<
      PaymentControllerGetAllEduOrgsApiResponse,
      PaymentControllerGetAllEduOrgsApiArg
    >({
      query: () => ({ url: `/api/payments/edu-list` }),
    }),
    paymentControllerCreatePayment: build.mutation<
      PaymentControllerCreatePaymentApiResponse,
      PaymentControllerCreatePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.paymentType}`,
        method: "POST",
        body: queryArg.paymentCreateRequest,
      }),
    }),
    paymentControllerFilterPayments: build.mutation<
      PaymentControllerFilterPaymentsApiResponse,
      PaymentControllerFilterPaymentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.paymentType}/filter`,
        method: "POST",
        body: queryArg.paymentsFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    paymentControllerDownloadPaymentReport: build.query<
      PaymentControllerDownloadPaymentReportApiResponse,
      PaymentControllerDownloadPaymentReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.paymentType}/download-report`,
        params: {
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
          eduOrgsIds: queryArg.eduOrgsIds,
        },
      }),
    }),
    paymentControllerGetPayment: build.query<
      PaymentControllerGetPaymentApiResponse,
      PaymentControllerGetPaymentApiArg
    >({
      query: (queryArg) => ({ url: `/api/payments/${queryArg.id}` }),
    }),
    paymentControllerChangeStatus: build.mutation<
      PaymentControllerChangeStatusApiResponse,
      PaymentControllerChangeStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.id}`,
        method: "PATCH",
      }),
    }),
    paymentControllerDeletePayment: build.mutation<
      PaymentControllerDeletePaymentApiResponse,
      PaymentControllerDeletePaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    paymentControllerGetEducationalOrganizations: build.query<
      PaymentControllerGetEducationalOrganizationsApiResponse,
      PaymentControllerGetEducationalOrganizationsApiArg
    >({
      query: (queryArg) => ({ url: `/api/payments/${queryArg.id}/edu-orgs` }),
    }),
    paymentControllerFilterCandidates: build.mutation<
      PaymentControllerFilterCandidatesApiResponse,
      PaymentControllerFilterCandidatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.id}/candidates/filter`,
        method: "POST",
        body: queryArg.paymentsCandidatesFiltersRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    paymentControllerDownloadCsvReport: build.query<
      PaymentControllerDownloadCsvReportApiResponse,
      PaymentControllerDownloadCsvReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.id}/download-approved-candidates-csv`,
      }),
    }),
    paymentControllerDownloadEduOrgReport: build.query<
      PaymentControllerDownloadEduOrgReportApiResponse,
      PaymentControllerDownloadEduOrgReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/payments/${queryArg.id}/edu-org/${queryArg.eduOrgId}/download/${queryArg.format}`,
      }),
    }),
    applicationControllerFilterApplications: build.mutation<
      ApplicationControllerFilterApplicationsApiResponse,
      ApplicationControllerFilterApplicationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/application/filter`,
        method: "POST",
        body: queryArg.applicationsFilterRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    applicationControllerGetApplication: build.query<
      ApplicationControllerGetApplicationApiResponse,
      ApplicationControllerGetApplicationApiArg
    >({
      query: (queryArg) => ({ url: `/api/application/${queryArg.id}` }),
    }),
    applicationControllerRejectApplication: build.mutation<
      ApplicationControllerRejectApplicationApiResponse,
      ApplicationControllerRejectApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/application/${queryArg.id}/reject`,
        method: "POST",
        body: queryArg.applicationRejectRequest,
      }),
    }),
    applicationControllerApproveApplication: build.mutation<
      ApplicationControllerApproveApplicationApiResponse,
      ApplicationControllerApproveApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/application/${queryArg.id}/approve`,
        method: "POST",
      }),
    }),
    humanNotificationsControllerGetMy: build.query<
      HumanNotificationsControllerGetMyApiResponse,
      HumanNotificationsControllerGetMyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/human-notifications/my`,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    authEmailControllerPostEmail: build.mutation<
      AuthEmailControllerPostEmailApiResponse,
      AuthEmailControllerPostEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/email`,
        method: "POST",
        body: queryArg.humanSignupRequest,
      }),
    }),
    authEmailControllerPostToken: build.mutation<
      AuthEmailControllerPostTokenApiResponse,
      AuthEmailControllerPostTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token`,
        method: "POST",
        body: queryArg.humanLoginRequest,
      }),
    }),
    authEmailControllerLoginByEmailCode: build.mutation<
      AuthEmailControllerLoginByEmailCodeApiResponse,
      AuthEmailControllerLoginByEmailCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-email-code`,
        method: "POST",
        body: queryArg.humanLoginEmailCodeRequest,
      }),
    }),
    authEmailControllerRefreshToken: build.mutation<
      AuthEmailControllerRefreshTokenApiResponse,
      AuthEmailControllerRefreshTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token/refresh`,
        method: "POST",
        body: queryArg.humanRefreshTokenRequest,
      }),
    }),
    authEmailControllerLogout: build.mutation<
      AuthEmailControllerLogoutApiResponse,
      AuthEmailControllerLogoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/logout`,
        method: "POST",
        body: queryArg.humanLogoutRequest,
      }),
    }),
    authEmailControllerDeleteCurrentUser: build.mutation<
      AuthEmailControllerDeleteCurrentUserApiResponse,
      AuthEmailControllerDeleteCurrentUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/delete-current-user`,
        method: "POST",
        body: queryArg.humanLogoutRequest,
      }),
    }),
    ticketControllerFilter: build.mutation<
      TicketControllerFilterApiResponse,
      TicketControllerFilterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ticket/filter`,
        method: "POST",
        body: queryArg.ticketFilterRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    ticketControllerGetById: build.query<
      TicketControllerGetByIdApiResponse,
      TicketControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ticket/${queryArg.id}` }),
    }),
    ticketControllerAnswerTicket: build.mutation<
      TicketControllerAnswerTicketApiResponse,
      TicketControllerAnswerTicketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ticket/${queryArg.id}/answer`,
        method: "POST",
        body: queryArg.ticketAnswerRequest,
      }),
    }),
    ticketControllerCreateTicket: build.mutation<
      TicketControllerCreateTicketApiResponse,
      TicketControllerCreateTicketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ticket`,
        method: "POST",
        body: queryArg.participantTicketRequest,
      }),
    }),
    partnerControllerSyncAll: build.query<
      PartnerControllerSyncAllApiResponse,
      PartnerControllerSyncAllApiArg
    >({
      query: () => ({ url: `/api/partner/sync-all` }),
    }),
    partnerControllerGetCities: build.query<
      PartnerControllerGetCitiesApiResponse,
      PartnerControllerGetCitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/partner/cities`,
        params: { search: queryArg.search },
      }),
    }),
    partnerControllerGetCategories: build.query<
      PartnerControllerGetCategoriesApiResponse,
      PartnerControllerGetCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/partner/categories`,
        params: { search: queryArg.search },
      }),
    }),
    partnerControllerGetPartner: build.query<
      PartnerControllerGetPartnerApiResponse,
      PartnerControllerGetPartnerApiArg
    >({
      query: (queryArg) => ({ url: `/api/partner/${queryArg.id}` }),
    }),
    partnerControllerUpdatePartner: build.mutation<
      PartnerControllerUpdatePartnerApiResponse,
      PartnerControllerUpdatePartnerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/partner/${queryArg.id}`,
        method: "PUT",
        body: queryArg.partnerUpdateRequest,
      }),
    }),
    partnerControllerUploadFile: build.mutation<
      PartnerControllerUploadFileApiResponse,
      PartnerControllerUploadFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/partner/${queryArg.id}/logo`,
        method: "PUT",
        body: queryArg.partnerUpdateLogoRequest,
      }),
    }),
    partnerControllerFilterPartners: build.mutation<
      PartnerControllerFilterPartnersApiResponse,
      PartnerControllerFilterPartnersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/partner/filter`,
        method: "POST",
        body: queryArg.partnerFilterRequest,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    nsiSyncControllerWebhook: build.mutation<
      NsiSyncControllerWebhookApiResponse,
      NsiSyncControllerWebhookApiArg
    >({
      query: (queryArg) => ({
        url: `/api/nsi-sync/webhook/${queryArg.operationType}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    nsiSyncControllerFilterSyncDataLogs: build.mutation<
      NsiSyncControllerFilterSyncDataLogsApiResponse,
      NsiSyncControllerFilterSyncDataLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/nsi-sync/sync-data/incoming`,
        method: "POST",
        body: queryArg.syncDataFilterRequestDto,
        params: { skip: queryArg.skip, size: queryArg.size, asc: queryArg.asc },
      }),
    }),
    nsiSyncControllerGetSyncDataLog: build.query<
      NsiSyncControllerGetSyncDataLogApiResponse,
      NsiSyncControllerGetSyncDataLogApiArg
    >({
      query: (queryArg) => ({
        url: `/api/nsi-sync/sync-data/incoming/${queryArg.id}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as adminGeneratedApi };
export type AuthControllerLoginApiResponse =
  /** status 200  */ LoginResponseDto;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerRefreshApiResponse =
  /** status 200  */ RefreshResponseDto;
export type AuthControllerRefreshApiArg = {
  refreshDto: RefreshDto;
};
export type AuthControllerLogin2FaApiResponse =
  /** status 200  */ LoginResponseDto;
export type AuthControllerLogin2FaApiArg = {
  otpCreatorDto: OtpCreatorDto;
};
export type MetricsControllerIndexApiResponse = unknown;
export type MetricsControllerIndexApiArg = void;
export type UsersControllerFilterUsersApiResponse = /** status 200  */ {
  data?: UserResponse[];
  paginate?: PaginationResponseMetrics;
};
export type UsersControllerFilterUsersApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  userFilterDto: UserFilterDto;
};
export type UsersControllerGetCurrentUserApiResponse =
  /** status 200  */ UserResponse;
export type UsersControllerGetCurrentUserApiArg = void;
export type UsersControllerUpdateCurrentUserApiResponse =
  /** status 200  */ UserResponse;
export type UsersControllerUpdateCurrentUserApiArg = {
  userUpdaterDto: UserUpdaterDto;
};
export type UsersControllerGetUserApiResponse = /** status 200  */ UserResponse;
export type UsersControllerGetUserApiArg = {
  id: string;
};
export type UsersControllerUpdateUserApiResponse =
  /** status 200  */ UserResponse;
export type UsersControllerUpdateUserApiArg = {
  id: string;
  userUpdaterDto: UserUpdaterDto;
};
export type UsersControllerDeleteUserApiResponse = unknown;
export type UsersControllerDeleteUserApiArg = {
  id: string;
};
export type UsersControllerCreateUserApiResponse =
  /** status 201  */ UserResponse;
export type UsersControllerCreateUserApiArg = {
  userCreatorDto: UserCreatorDto;
};
export type UsersControllerUpdateUserRoleApiResponse =
  /** status 200  */ UserResponse;
export type UsersControllerUpdateUserRoleApiArg = {
  id: string;
  userRoleUpdaterDto: UserRoleUpdaterDto;
};
export type UsersControllerUpdateUserActiveStatusApiResponse =
  /** status 200  */ UserResponse;
export type UsersControllerUpdateUserActiveStatusApiArg = {
  id: string;
  userActiveStatusUpdaterDto: UserActiveStatusUpdaterDto;
};
export type UsersControllerChangeCurrentUserPasswordApiResponse = unknown;
export type UsersControllerChangeCurrentUserPasswordApiArg = {
  userPasswordUpdaterDto: UserPasswordUpdaterDto;
};
export type UsersControllerResetPasswordApiResponse = unknown;
export type UsersControllerResetPasswordApiArg = {
  id: string;
};
export type UsersControllerUpdateCurrentUserPhotoApiResponse = unknown;
export type UsersControllerUpdateCurrentUserPhotoApiArg = {
  body: {
    file?: Blob;
  };
};
export type NotificationsControllerFilterApiResponse = /** status 200  */ {
  data?: NotificationsShortResponse[];
  paginate?: PaginationResponseMetrics;
};
export type NotificationsControllerFilterApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  notificationsFiltersRequest: NotificationsFiltersRequest;
};
export type NotificationsControllerGetByIdApiResponse =
  /** status 200  */ NotificationsResponse;
export type NotificationsControllerGetByIdApiArg = {
  id: string;
};
export type NotificationsControllerUpdateApiResponse =
  /** status 200  */ NotificationsResponse;
export type NotificationsControllerUpdateApiArg = {
  id: string;
  notificationsRequest: NotificationsRequest;
};
export type NotificationsControllerDeleteApiResponse = unknown;
export type NotificationsControllerDeleteApiArg = {
  id: string;
};
export type NotificationsControllerCreateApiResponse =
  /** status 200  */ NotificationsResponse;
export type NotificationsControllerCreateApiArg = {
  notificationsRequest: NotificationsRequest;
};
export type NotificationsControllerSendEmailApiResponse =
  /** status 200  */ NotificationsResponse;
export type NotificationsControllerSendEmailApiArg = {
  id: string;
};
export type SettingsControllerGetApiResponse =
  /** status 200  */ SettingResponse[];
export type SettingsControllerGetApiArg = void;
export type SettingsControllerUpdateApiResponse =
  /** status 200  */ SettingResponse;
export type SettingsControllerUpdateApiArg = {
  id: string;
  settingUpdaterDto: SettingUpdaterDto;
};
export type FilesControllerGetApiResponse = /** status 200  */ Blob;
export type FilesControllerGetApiArg = {
  bucket: string;
  fileName: string;
};
export type FilesControllerDeleteFileApiResponse = unknown;
export type FilesControllerDeleteFileApiArg = {
  fileId: string;
};
export type FilesControllerEditFileDescriptionsApiResponse = unknown;
export type FilesControllerEditFileDescriptionsApiArg = {
  fileId: string;
  mediaFileEditDto: MediaFileEditDto;
};
export type FilesControllerUploadFileApiResponse =
  /** status 201  */ MediaFileResponse;
export type FilesControllerUploadFileApiArg = {
  subjectId: string;
  mediaFileUpdaterDto: MediaFileUpdaterDto;
};
export type ResourcesControllerLanguagesApiResponse = /** status 200  */ {
  data?: LanguageResponse[];
  paginate?: PaginationResponseMetrics;
};
export type ResourcesControllerLanguagesApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
};
export type ResourcesControllerCreateOrUpdateLanguageApiResponse = unknown;
export type ResourcesControllerCreateOrUpdateLanguageApiArg = {
  languageRequest: LanguageRequest;
};
export type ResourcesControllerGetTranslationsApiResponse = /** status 200  */ {
  data?: TranslationResponse[];
  paginate?: PaginationResponseMetrics;
};
export type ResourcesControllerGetTranslationsApiArg = {
  languageId?: string;
  mnemonic?: string;
  skip?: number;
  size?: number;
  asc?: boolean;
};
export type ResourcesControllerCreateOrUpdateTranslationApiResponse = unknown;
export type ResourcesControllerCreateOrUpdateTranslationApiArg = {
  translationRequest: TranslationRequest;
};
export type ResourcesControllerUserRolesApiResponse =
  /** status 200  */ UserRoleResponse[];
export type ResourcesControllerUserRolesApiArg = void;
export type ResourcesControllerTestI18ApiResponse = /** status 200  */ string;
export type ResourcesControllerTestI18ApiArg = void;
export type ResourcesControllerGetEskIssuingApiResponse =
  /** status 200  */ EskIssuerListResponse;
export type ResourcesControllerGetEskIssuingApiArg = void;
export type ResourcesControllerGetPublicSettingsApiResponse =
  /** status 200  */ SettingResponse[];
export type ResourcesControllerGetPublicSettingsApiArg = void;
export type ResourcesControllerServiceWorksApiResponse =
  /** status 200  */ boolean;
export type ResourcesControllerServiceWorksApiArg = void;
export type ResourcesControllerGetEduOrgsApiResponse =
  /** status 200  */ EduOrgResponse[];
export type ResourcesControllerGetEduOrgsApiArg = void;
export type NewsTagsControllerGetNewsTagsApiResponse =
  /** status 200  */ NewsTagResponse[];
export type NewsTagsControllerGetNewsTagsApiArg = void;
export type NewsTagsControllerCreateNewsTagApiResponse =
  /** status 200  */ NewsTagResponse;
export type NewsTagsControllerCreateNewsTagApiArg = {
  newsTagRequest: NewsTagRequest;
};
export type NewsTagsControllerUpdateNewsTagApiResponse =
  /** status 200  */ NewsTagResponse;
export type NewsTagsControllerUpdateNewsTagApiArg = {
  id: string;
  newsTagRequest: NewsTagRequest;
};
export type NewsTagsControllerDeleteNewsTagApiResponse = unknown;
export type NewsTagsControllerDeleteNewsTagApiArg = {
  id: string;
};
export type AuthorsControllerGetAuthorsApiResponse =
  /** status 200  */ AuthorResponse[];
export type AuthorsControllerGetAuthorsApiArg = void;
export type AuthorsControllerCreateAuthorApiResponse =
  /** status 200  */ AuthorResponse;
export type AuthorsControllerCreateAuthorApiArg = {
  authorRequest: AuthorRequest;
};
export type AuthorsControllerUpdateAuthorApiResponse =
  /** status 200  */ AuthorResponse;
export type AuthorsControllerUpdateAuthorApiArg = {
  id: string;
  authorRequest: AuthorRequest;
};
export type AuthorsControllerDeleteAuthorApiResponse = unknown;
export type AuthorsControllerDeleteAuthorApiArg = {
  id: string;
};
export type NewsControllerFilterNewsApiResponse = /** status 200  */ {
  data?: NewsShortResponse[];
  paginate?: PaginationResponseMetrics;
};
export type NewsControllerFilterNewsApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  newsFiltersRequest: NewsFiltersRequest;
};
export type NewsControllerGetNewsApiResponse = /** status 200  */ NewsResponse;
export type NewsControllerGetNewsApiArg = {
  id: string;
};
export type NewsControllerUpdateNewsApiResponse =
  /** status 200  */ NewsResponse;
export type NewsControllerUpdateNewsApiArg = {
  id: string;
  newsRequest: NewsRequest;
};
export type NewsControllerDeleteNewsApiResponse = unknown;
export type NewsControllerDeleteNewsApiArg = {
  id: string;
};
export type NewsControllerGetNewsByUrlApiResponse =
  /** status 200  */ NewsResponse;
export type NewsControllerGetNewsByUrlApiArg = {
  url: string;
};
export type NewsControllerCreateNewsApiResponse =
  /** status 200  */ NewsResponse;
export type NewsControllerCreateNewsApiArg = {
  newsRequest: NewsRequest;
};
export type EventsControllerFilterEventsApiResponse = /** status 200  */ {
  data?: EventShortResponse[];
  paginate?: PaginationResponseMetrics;
};
export type EventsControllerFilterEventsApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  eventFiltersRequest: EventFiltersRequest;
};
export type EventsControllerGetEventApiResponse =
  /** status 200  */ EventResponse;
export type EventsControllerGetEventApiArg = {
  id: string;
};
export type EventsControllerUpdateEventApiResponse =
  /** status 200  */ EventResponse;
export type EventsControllerUpdateEventApiArg = {
  id: string;
  eventRequest: EventRequest;
};
export type EventsControllerDeleteEventApiResponse = unknown;
export type EventsControllerDeleteEventApiArg = {
  id: string;
};
export type EventsControllerGetEventBuUrlApiResponse =
  /** status 200  */ EventResponse;
export type EventsControllerGetEventBuUrlApiArg = {
  url: string;
};
export type EventsControllerCreateEventApiResponse =
  /** status 200  */ EventResponse;
export type EventsControllerCreateEventApiArg = {
  eventRequest: EventRequest;
};
export type InfoPagesControllerFilterInfoPagesApiResponse = /** status 200  */ {
  data?: InfoPageShortResponse[];
  paginate?: PaginationResponseMetrics;
};
export type InfoPagesControllerFilterInfoPagesApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  infoPageFiltersRequest: InfoPageFiltersRequest;
};
export type InfoPagesControllerGetInfoPageApiResponse =
  /** status 200  */ InfoPageResponse;
export type InfoPagesControllerGetInfoPageApiArg = {
  id: string;
};
export type InfoPagesControllerUpdateInfoPageApiResponse =
  /** status 200  */ InfoPageResponse;
export type InfoPagesControllerUpdateInfoPageApiArg = {
  id: string;
  infoPageRequest: InfoPageRequest;
};
export type InfoPagesControllerDeleteInfoPageApiResponse = unknown;
export type InfoPagesControllerDeleteInfoPageApiArg = {
  id: string;
};
export type InfoPagesControllerGetInfoPageBuUrlApiResponse =
  /** status 200  */ InfoPageResponse;
export type InfoPagesControllerGetInfoPageBuUrlApiArg = {
  url: string;
};
export type InfoPagesControllerCreateInfoPageApiResponse =
  /** status 200  */ InfoPageResponse;
export type InfoPagesControllerCreateInfoPageApiArg = {
  infoPageRequest: InfoPageRequest;
};
export type ParticipantsControllerGetCurrentHumanApiResponse =
  /** status 200  */ HumanResponse;
export type ParticipantsControllerGetCurrentHumanApiArg = void;
export type ParticipantsControllerPutCurrentHumanApiResponse =
  /** status 200  */ HumanResponse;
export type ParticipantsControllerPutCurrentHumanApiArg = {
  humanRequest: HumanRequest;
};
export type ParticipantsControllerPutCurrentHumanIdentityApiResponse =
  /** status 200  */ HumanResponse;
export type ParticipantsControllerPutCurrentHumanIdentityApiArg = {
  humanIdentityRequest: HumanIdentityRequest;
};
export type ParticipantsControllerGetEskCardApiResponse =
  /** status 200  */ EskCardApplicationResponse;
export type ParticipantsControllerGetEskCardApiArg = void;
export type ParticipantsControllerGetWorkPlanApiResponse =
  /** status 200  */ StudentWorkPlanResponseItem[];
export type ParticipantsControllerGetWorkPlanApiArg = void;
export type ParticipantsControllerGetPayHistoryApiResponse =
  /** status 200  */ HumanPayResponse;
export type ParticipantsControllerGetPayHistoryApiArg = void;
export type ParticipantsControllerAddHumanFcmTokenApiResponse = unknown;
export type ParticipantsControllerAddHumanFcmTokenApiArg = {
  humanFcmTokenRequest: HumanFcmTokenRequest;
};
export type ParticipantsHumansControllerFilterHumansApiResponse =
  /** status 200  */ {
    data?: HumanShortResponse[];
    paginate?: PaginationResponseMetrics;
  };
export type ParticipantsHumansControllerFilterHumansApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  humansFilterRequest: HumansFilterRequest;
};
export type ParticipantsHumansControllerGetHumanApiResponse =
  /** status 200  */ HumanResponse;
export type ParticipantsHumansControllerGetHumanApiArg = {
  id: string;
};
export type ParticipantsHumansControllerGetHumanProgramsApiResponse =
  /** status 200  */ ProgramResponse[];
export type ParticipantsHumansControllerGetHumanProgramsApiArg = {
  id: string;
};
export type ParticipantsHumansControllerGetHumanEskCardApiResponse =
  /** status 200  */ EskCardApplicationResponse;
export type ParticipantsHumansControllerGetHumanEskCardApiArg = {
  id: string;
};
export type ParticipantsHumansControllerGetHumanWorkPlanApiResponse =
  /** status 200  */ StudentWorkPlanResponseItem[];
export type ParticipantsHumansControllerGetHumanWorkPlanApiArg = {
  id: string;
  period: string;
};
export type ParticipantsHumansControllerGetHumanPayHistoryApiResponse =
  /** status 200  */ HumanPayResponse;
export type ParticipantsHumansControllerGetHumanPayHistoryApiArg = {
  id: string;
};
export type ParticipantsHumansControllerGetHumanLogsApiResponse =
  /** status 200  */ HumanLogResponse[];
export type ParticipantsHumansControllerGetHumanLogsApiArg = {
  id: string;
};
export type ParticipantsHumansControllerUndeleteHumanApiResponse = unknown;
export type ParticipantsHumansControllerUndeleteHumanApiArg = {
  id: string;
};
export type StudentControllerStudentInfoApiResponse =
  /** status 200  */ HumanFindStudentResponse[];
export type StudentControllerStudentInfoApiArg = {
  eduOrgId: string;
};
export type StudentControllerApplyStudentApiResponse = unknown;
export type StudentControllerApplyStudentApiArg = {
  uuid: string;
};
export type ParticipantsApplicationsControllerCreateApplicationEskCardApiResponse =
  /** status 200  */ ApplicationShortResponse;
export type ParticipantsApplicationsControllerCreateApplicationEskCardApiArg =
  void;
export type ParticipantsApplicationsControllerCreateApplicationExtraGrantApiResponse =
  /** status 200  */ ApplicationShortResponse;
export type ParticipantsApplicationsControllerCreateApplicationExtraGrantApiArg =
  void;
export type ParticipantsApplicationsControllerCreateApplicationCreditGrantApiResponse =
  /** status 201  */ ApplicationShortResponse;
export type ParticipantsApplicationsControllerCreateApplicationCreditGrantApiArg =
  {
    creditApplicationRequest: CreditApplicationRequest;
  };
export type ParticipantsApplicationsControllerCancelApplicationApiResponse =
  /** status 204  */ ApplicationShortResponse;
export type ParticipantsApplicationsControllerCancelApplicationApiArg = {
  id: string;
};
export type ParticipantsApplicationsControllerGetHumanApplicationsApiResponse =
  /** status 200  */ ApplicationShortResponse[];
export type ParticipantsApplicationsControllerGetHumanApplicationsApiArg = {
  type?: string;
};
export type ParticipantsTicketControllerCreateTicketApiResponse =
  /** status 200  */ TicketShortResponse;
export type ParticipantsTicketControllerCreateTicketApiArg = {
  participantTicketRequest: ParticipantTicketRequest;
};
export type ParticipantsTicketControllerCreateParticipantsTicketApiResponse =
  /** status 200  */ TicketShortResponse;
export type ParticipantsTicketControllerCreateParticipantsTicketApiArg = {
  participantTicketRequest: ParticipantTicketRequest;
};
export type ParticipantsTicketControllerGetTicketsApiResponse =
  /** status 200  */ TicketShortResponse[];
export type ParticipantsTicketControllerGetTicketsApiArg = void;
export type EducationalOrganizationControllerGetEduOrgGrantsApiResponse =
  /** status 200  */ EducationalOrganizationResponse[];
export type EducationalOrganizationControllerGetEduOrgGrantsApiArg = {
  search: string;
};
export type EducationalOrganizationControllerSetEduOrgGrantApiResponse =
  unknown;
export type EducationalOrganizationControllerSetEduOrgGrantApiArg = {
  eduId: string;
  educationalOrganizationGrantRequest: EducationalOrganizationGrantRequest;
};
export type PaymentControllerGetAllEduOrgsApiResponse =
  /** status 200  */ EducationalOrganizationResponse[];
export type PaymentControllerGetAllEduOrgsApiArg = void;
export type PaymentControllerCreatePaymentApiResponse = unknown;
export type PaymentControllerCreatePaymentApiArg = {
  paymentType: "creditGrant" | "extraGrant";
  paymentCreateRequest: PaymentCreateRequest;
};
export type PaymentControllerFilterPaymentsApiResponse = /** status 200  */ {
  data?: PaymentShortResponse[];
  paginate?: PaginationResponseMetrics;
};
export type PaymentControllerFilterPaymentsApiArg = {
  paymentType: "creditGrant" | "extraGrant";
  skip?: number;
  size?: number;
  asc?: boolean;
  paymentsFiltersRequest: PaymentsFiltersRequest;
};
export type PaymentControllerDownloadPaymentReportApiResponse = unknown;
export type PaymentControllerDownloadPaymentReportApiArg = {
  paymentType: "creditGrant" | "extraGrant";
  fromDate?: string;
  toDate?: string;
  eduOrgsIds?: string[];
};
export type PaymentControllerGetPaymentApiResponse =
  /** status 200  */ PaymentResponse;
export type PaymentControllerGetPaymentApiArg = {
  id: string;
};
export type PaymentControllerChangeStatusApiResponse = unknown;
export type PaymentControllerChangeStatusApiArg = {
  id: string;
};
export type PaymentControllerDeletePaymentApiResponse = unknown;
export type PaymentControllerDeletePaymentApiArg = {
  id: string;
};
export type PaymentControllerGetEducationalOrganizationsApiResponse =
  /** status 200  */ EducationalOrganizationResponse[];
export type PaymentControllerGetEducationalOrganizationsApiArg = {
  id: string;
};
export type PaymentControllerFilterCandidatesApiResponse = /** status 200  */ {
  data?: PaymentCandidatesResponse[];
  paginate?: PaginationResponseMetrics;
};
export type PaymentControllerFilterCandidatesApiArg = {
  id: string;
  skip?: number;
  size?: number;
  asc?: boolean;
  paymentsCandidatesFiltersRequest: PaymentsCandidatesFiltersRequest;
};
export type PaymentControllerDownloadCsvReportApiResponse = unknown;
export type PaymentControllerDownloadCsvReportApiArg = {
  id: string;
};
export type PaymentControllerDownloadEduOrgReportApiResponse = unknown;
export type PaymentControllerDownloadEduOrgReportApiArg = {
  id: string;
  eduOrgId: string;
  format: "csv" | "xlsx" | "pdf";
};
export type ApplicationControllerFilterApplicationsApiResponse =
  /** status 200  */ {
    data?: ApplicationResponse[];
    paginate?: PaginationResponseMetrics;
  };
export type ApplicationControllerFilterApplicationsApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  applicationsFilterRequest: ApplicationsFilterRequest;
};
export type ApplicationControllerGetApplicationApiResponse =
  /** status 200  */ ApplicationResponse;
export type ApplicationControllerGetApplicationApiArg = {
  id: string;
};
export type ApplicationControllerRejectApplicationApiResponse = unknown;
export type ApplicationControllerRejectApplicationApiArg = {
  id: string;
  applicationRejectRequest: ApplicationRejectRequest;
};
export type ApplicationControllerApproveApplicationApiResponse = unknown;
export type ApplicationControllerApproveApplicationApiArg = {
  id: string;
};
export type HumanNotificationsControllerGetMyApiResponse = /** status 200  */ {
  data?: HumanNotificationResponse[];
  paginate?: PaginationResponseMetrics;
};
export type HumanNotificationsControllerGetMyApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
};
export type AuthEmailControllerPostEmailApiResponse =
  /** status 200  */ undefined;
export type AuthEmailControllerPostEmailApiArg = {
  humanSignupRequest: HumanSignupRequest;
};
export type AuthEmailControllerPostTokenApiResponse =
  /** status 200  */ HumanLoginResponse;
export type AuthEmailControllerPostTokenApiArg = {
  humanLoginRequest: HumanLoginRequest;
};
export type AuthEmailControllerLoginByEmailCodeApiResponse =
  /** status 200  */ HumanLoginResponse;
export type AuthEmailControllerLoginByEmailCodeApiArg = {
  humanLoginEmailCodeRequest: HumanLoginEmailCodeRequest;
};
export type AuthEmailControllerRefreshTokenApiResponse =
  /** status 200  */ HumanLoginResponse;
export type AuthEmailControllerRefreshTokenApiArg = {
  humanRefreshTokenRequest: HumanRefreshTokenRequest;
};
export type AuthEmailControllerLogoutApiResponse = unknown;
export type AuthEmailControllerLogoutApiArg = {
  humanLogoutRequest: HumanLogoutRequest;
};
export type AuthEmailControllerDeleteCurrentUserApiResponse = unknown;
export type AuthEmailControllerDeleteCurrentUserApiArg = {
  humanLogoutRequest: HumanLogoutRequest;
};
export type TicketControllerFilterApiResponse = /** status 200  */ {
  data?: TicketShortResponse[];
  paginate?: PaginationResponseMetrics;
  totalUnresolved?: number;
};
export type TicketControllerFilterApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  ticketFilterRequest: TicketFilterRequest;
};
export type TicketControllerGetByIdApiResponse =
  /** status 200  */ TicketResponse;
export type TicketControllerGetByIdApiArg = {
  id: string;
};
export type TicketControllerAnswerTicketApiResponse =
  /** status 200  */ TicketResponse;
export type TicketControllerAnswerTicketApiArg = {
  id: string;
  ticketAnswerRequest: TicketAnswerRequest;
};
export type TicketControllerCreateTicketApiResponse =
  /** status 200  */ TicketShortResponse;
export type TicketControllerCreateTicketApiArg = {
  participantTicketRequest: ParticipantTicketRequest;
};
export type PartnerControllerSyncAllApiResponse = unknown;
export type PartnerControllerSyncAllApiArg = void;
export type PartnerControllerGetCitiesApiResponse =
  /** status 200  */ PartnerCityResponse[];
export type PartnerControllerGetCitiesApiArg = {
  search?: string;
};
export type PartnerControllerGetCategoriesApiResponse =
  /** status 200  */ PartnerCategoryResponse[];
export type PartnerControllerGetCategoriesApiArg = {
  search?: string;
};
export type PartnerControllerGetPartnerApiResponse =
  /** status 200  */ PartnerResponse;
export type PartnerControllerGetPartnerApiArg = {
  id: string;
};
export type PartnerControllerUpdatePartnerApiResponse =
  /** status 200  */ PartnerResponse;
export type PartnerControllerUpdatePartnerApiArg = {
  id: string;
  partnerUpdateRequest: PartnerUpdateRequest;
};
export type PartnerControllerUploadFileApiResponse = unknown;
export type PartnerControllerUploadFileApiArg = {
  id: string;
  partnerUpdateLogoRequest: PartnerUpdateLogoRequest;
};
export type PartnerControllerFilterPartnersApiResponse = /** status 200  */ {
  data?: PartnerShortResponse[];
  paginate?: PaginationResponseMetrics;
};
export type PartnerControllerFilterPartnersApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  partnerFilterRequest: PartnerFilterRequest;
};
export type NsiSyncControllerWebhookApiResponse = unknown;
export type NsiSyncControllerWebhookApiArg = {
  operationType: string;
  body: any;
};
export type NsiSyncControllerFilterSyncDataLogsApiResponse =
  /** status 200  */ {
    data?: SyncDataResponseDto[];
    paginate?: PaginationResponseMetrics;
  };
export type NsiSyncControllerFilterSyncDataLogsApiArg = {
  skip?: number;
  size?: number;
  asc?: boolean;
  syncDataFilterRequestDto: SyncDataFilterRequestDto;
};
export type NsiSyncControllerGetSyncDataLogApiResponse =
  /** status 200  */ SyncDataResponseDto;
export type NsiSyncControllerGetSyncDataLogApiArg = {
  id: string;
};
export type LoginResponseDto = {
  accessToken?: string;
  otpSecret?: string;
  isConfirmed: boolean;
};
export type DefaultException = {
  statusCode: number;
  message: string;
  errors: any[];
};
export type LoginDto = {
  email: string;
  password: string;
};
export type RefreshResponseDto = {
  accessToken: string;
};
export type RefreshDto = {
  token: string;
};
export type OtpCreatorDto = {
  otp: string;
  email: string;
};
export type LanguageResponse = {
  key: string;
  title: string;
  id: string;
};
export type FileType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type MediaFileResponse = {
  id: string;
  bucket: string;
  type: FileType;
  fileName: string;
  originalFileName: string;
  mimeType: string;
  size: number;
  url: string;
  metadata: string;
  alt: string;
};
export type UserResponse = {
  id: string;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickname?: string;
  changePasswordDate?: string;
  metadata?: string;
  isConfirmed: boolean;
  isActive: boolean;
  role: (0 | 1 | 2 | 4 | 8)[];
  lastLoginDate: string;
  language?: LanguageResponse;
  avatar?: MediaFileResponse;
};
export type PaginationResponseMetrics = {
  totalCount: number;
};
export type UserFilterDto = {
  search?: string;
  role?: (0 | 1 | 2 | 4 | 8)[];
  isActive?: boolean;
};
export type UserUpdaterDto = {
  languageId?: string;
  metadata?: string;
  nickname?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
};
export type UserCreatorDto = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email: string;
  role: (0 | 1 | 2 | 4 | 8)[];
  nickname?: string;
};
export type UserRoleUpdaterDto = {
  role: (0 | 1 | 2 | 4 | 8)[];
};
export type UserActiveStatusUpdaterDto = {
  isActive: boolean;
};
export type UserPasswordUpdaterDto = {
  password: string;
};
export type NotificationsShortResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: number;
  sendAt: string;
  type: number;
  title: string;
};
export type NotificationsFiltersRequest = {
  search?: string;
  type?: number;
};
export type NotificationsResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: number;
  sendAt: string;
  type: number;
  title: string;
  text: string;
  targets: any[];
  sender?: string;
  template?: string;
  link?: string;
  image?: string;
};
export type NotificationsRequest = {
  type: number;
  title: string;
  text: string;
  targets: string[];
  template?: string;
  sender?: string;
  link?: string;
  image?: string;
  sendAt?: string;
};
export type SettingResponse = {
  id: string;
  title: string;
  groupName: string;
  order: number;
  help: string;
  type: number;
  label: string;
  minValue: number;
  maxValue: number;
  regex: string;
  regexFlags: string;
  defaultValue: string;
  value: string;
};
export type SettingUpdaterDto = {
  value: string;
};
export type MediaFileEditDto = {
  alt: string;
  metadata: string;
};
export type MediaFileUpdaterDto = {
  file: Blob;
  type: FileType;
  alt?: string;
  metadata?: string;
};
export type LanguageRequest = {
  key: string;
  title: string;
};
export type TranslationResponse = {
  id: string;
  languageKey: string;
  languageId: string;
  translation: string;
  mnemonic: string;
};
export type TranslationRequest = {
  languageId: string;
  mnemonic: string;
  translation: string;
};
export type UserRoleResponse = {
  role: number;
  label: string;
};
export type EskIssuerResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  latitude: string;
  longitude: string;
  name?: string;
  address?: string;
  organization?: string;
};
export type EskIssuerListResponse = {
  data: EskIssuerResponse[];
};
export type EduOrgResponse = {
  id: string;
  title: string;
  shortTitle?: string;
};
export type NewsTagResponse = {
  id: string;
  title: string;
};
export type NewsTagRequest = {
  title: string;
};
export type AuthorResponse = {
  id: string;
  name: string;
};
export type AuthorRequest = {
  name: string;
};
export type NewsShortResponse = {
  id: string;
  title: string;
  url: string;
  published: boolean;
  dateStart: string;
  type: string;
  fullTitle: string;
  tags: NewsTagResponse[];
  mainImage?: MediaFileResponse;
};
export type NewsFiltersRequest = {
  startDateFrom?: string;
  startDateTo?: string;
  type?: string;
  search?: string;
  tagIds?: any[];
  authorIds?: any[];
  excludeDrafts: boolean;
  excludeFuture: boolean;
};
export type MetaResponse = {
  id: string;
  name: string;
  content: string;
};
export type NewsResponse = {
  id: string;
  title: string;
  url: string;
  published: boolean;
  startDate: string;
  type: string;
  fullTitle: string;
  editorContent: string;
  tags: NewsTagResponse[];
  authors: AuthorResponse[];
  metaTags: MetaResponse[];
  embedded?: string;
  mainImage?: MediaFileResponse;
  photoGallery?: MediaFileResponse[];
  snippetPhoto?: MediaFileResponse;
};
export type MetaRequest = {
  name: string;
  content: string;
};
export type NewsRequest = {
  published: boolean;
  startDate: string;
  type: string;
  fullTitle?: string;
  title: string;
  url: string;
  editorContent: string;
  newsTagIds: string[];
  authorIds: string[];
  metaTags: MetaRequest[];
  embedded?: string;
};
export type EventShortResponse = {
  id: string;
  published: boolean;
  important: boolean;
  dateStart: string;
  dateEnd: string;
  locationName: string;
  locationCoordinates: string;
  title: string;
  url: string;
  type: string;
  mainImage?: MediaFileResponse;
};
export type EventFiltersRequest = {
  dateStartFrom?: string;
  dateStartTo?: string;
  dateEndFrom?: string;
  dateEndTo?: string;
  type?: string;
  search?: string;
  excludeDrafts: boolean;
  excludeFuture: boolean;
  important?: boolean;
};
export type EventResponse = {
  id: string;
  published: boolean;
  important: boolean;
  dateStart: string;
  dateEnd: string;
  locationName: string;
  locationCoordinates: string;
  title: string;
  url: string;
  type: string;
  editorContent: string;
  metaTags: MetaResponse[];
  embedded?: string;
  mainImage?: MediaFileResponse;
  photoGallery?: MediaFileResponse[];
  snippetPhoto?: MediaFileResponse;
};
export type EventRequest = {
  published: boolean;
  important: boolean;
  dateStart: string;
  dateEnd?: string;
  title: string;
  url: string;
  locationName: string;
  locationCoordinates?: string;
  type: string;
  editorContent: string;
  metaTags: MetaRequest[];
  embedded?: string;
};
export type InfoPageShortResponse = {
  id: string;
  published: boolean;
  title: string;
  url: string;
  mainImage?: MediaFileResponse;
  isMenu: boolean;
};
export type InfoPageFiltersRequest = {
  search?: string;
  excludeDrafts?: boolean;
  isMenu?: boolean;
};
export type InfoPageResponse = {
  id: string;
  published: boolean;
  title: string;
  url: string;
  editorContent: string;
  metaTags: MetaResponse[];
  embedded?: string;
  mainImage?: MediaFileResponse;
  photoGallery?: MediaFileResponse[];
  snippetPhoto?: MediaFileResponse;
  isMenu: boolean;
};
export type InfoPageRequest = {
  published: boolean;
  title: string;
  url: string;
  editorContent: string;
  metaTags: MetaRequest[];
  embedded?: string;
  isMenu?: boolean;
};
export type HumanCitizenshipResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  code: number;
  shortName: string;
  fullName: string;
};
export type IdentityCardResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  main: boolean;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  birthDate?: string;
  birthPlace?: string;
  series: string;
  number: string;
  giveDate?: string;
  departmentCode?: string;
  whoGive?: string;
  nationality?: string;
  addressString?: string;
};
export type EducationalOrganizationGrantResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  eduId: string;
  price: number;
};
export type EducationalOrganizationResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  fullName: string;
  nameShort: string;
  webpage: string;
  phone: string;
  email: string;
  grant: EducationalOrganizationGrantResponse;
};
export type DevelopFormResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  dataOwner: string;
  name: string;
  nameShort: string;
  group: string;
};
export type EducationalProgramsResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  okso: string;
  developForm: DevelopFormResponse;
};
export type StudentStatusResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  dataOwner: string;
  name: string;
  nameShort: string;
  active: boolean;
  usedAsAcademicLeave: boolean;
};
export type StudentCategoryResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  dataOwner: string;
  name: string;
};
export type CompensationTypeResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  dataOwner: string;
  name: string;
  nameShort: string;
  group: string;
};
export type StudentResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  courseNumber: string;
  personalNumber: string;
  personalAccount: string;
  entranceYear: string;
  target: string;
  yearEnd: string;
  startDate: string;
  endDate: string;
  educationalOrganization: EducationalOrganizationResponse;
  educationalProgram: EducationalProgramsResponse;
  studentStatus: StudentStatusResponse;
  studentCategory: StudentCategoryResponse;
  compensationType: CompensationTypeResponse;
};
export type HumanResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isActive: boolean;
  email: string;
  emailVerifiedDate?: string;
  acceptPdDate?: string;
  lastLoginDate?: string;
  phoneNumber?: string;
  birthDate?: string;
  birthPlace?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  inn?: string;
  snils?: string;
  sex?: number;
  citizenship?: HumanCitizenshipResponse;
  identityCards: IdentityCardResponse[];
  student?: StudentResponse;
  isAcceptedNoteEmail: boolean;
  isAcceptedNotePush: boolean;
  isAcceptedPd: boolean;
  externalId?: string;
  language?: LanguageResponse;
};
export type HumanRequest = {
  languageId?: string;
  firstName?: string;
  lastName?: string;
  isAcceptedNoteEmail?: boolean;
  isAcceptedNotePush?: boolean;
  isAcceptedPd?: boolean;
};
export type HumanIdentityRequest = {
  snils?: string;
  passportNumber?: string;
  passportSeries?: string;
  captchaToken?: string;
  deviceId?: string;
  platform?: string;
};
export type EskCardResponse = {
  id: string;
  number: string;
  dateStart: string;
  dateEnd: string;
  active: boolean;
  dataOwner: string;
};
export type EskCardApplicationResponse = {
  data?: EskCardResponse;
};
export type StudentWorkPlanResponseItem = {
  documentName: string;
  yearName: string;
  distributionPartLabel: string;
  disciplineName: string;
  teacher: string;
  type: string;
  mark: string;
  markString: string;
  markInSession: string;
  performDate: string;
  markInSessionString: string;
};
export type HumanPayResponseItem = {
  date: string;
  number: number;
  price: number;
  type: string;
};
export type HumanPayResponse = {
  data: HumanPayResponseItem[];
};
export type HumanFcmTokenRequest = {
  deviceId: string;
  token: string;
  platform: string;
};
export type HumanShortResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isActive: boolean;
  email: string;
  emailVerifiedDate?: string;
  acceptPdDate?: string;
  lastLoginDate?: string;
  phoneNumber?: string;
  birthDate?: string;
  birthPlace?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  inn?: string;
  snils?: string;
  sex?: number;
  isStudent: boolean;
  isAcceptedNoteEmail: boolean;
  isAcceptedNotePush: boolean;
  educationalOrganizationShortName: string;
};
export type HumansFilterRequest = {
  search?: string;
  isActive?: boolean;
  isStudent?: boolean;
  type?: string;
  programType?: string;
  registrationDateFrom?: string;
  registrationDateTo?: string;
  unconfirmedByEduOrgs?: string[];
  eduOrgs?: string[];
};
export type CreditApplicationResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  contractNumber: string;
  contractDate: string;
  personalAccountNumber: string;
  inn: string;
};
export type ApplicationResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  type: string;
  status: number;
  statusText: string;
  human: HumanResponse;
  credit?: CreditApplicationResponse;
  creditFiles?: MediaFileResponse[];
};
export type ProgramResponse = {
  type: "esk_card" | "extra_grant" | "credit_grant";
  isApproved: boolean;
  application?: ApplicationResponse;
};
export type HumanLogResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isError: boolean;
  text: string;
  type: string;
  organizationShortName: string;
  organizationExternalId: string;
};
export type HumanFindStudentResponse = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  departmentTitle?: string;
  educationalProgramTitle?: string;
  course?: string;
  email?: string;
  applyId?: string;
};
export type ApplicationShortResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  type: string;
  status: number;
  statusText: string;
  human: HumanResponse;
};
export type CreditApplicationRequest = {
  contractNumber: string;
  contractDate: string;
  personalAccountNumber: string;
  inn: string;
  files: Blob[];
};
export type TicketShortResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  subject: string;
  resolved: boolean;
  human?: HumanResponse;
  humanName?: string;
  humanEmail?: string;
};
export type ParticipantTicketRequest = {
  subject: string;
  text: string;
  userId?: string;
  userName?: string;
  userEmail?: string;
};
export type EducationalOrganizationGrantRequest = {
  price: number;
};
export type PaymentCreateRequest = {
  period: string;
};
export type PaymentShortResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  type: string;
  status: 0 | 1 | 2 | 3;
  approvedAt?: string;
  startedBy: UserResponse;
  studentsCount: number;
  price: number;
  sum: number;
};
export type PaymentsFiltersRequest = {
  confirmationDateFrom?: string;
  confirmationDateTo?: string;
};
export type PaymentStatusChangesResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  fromStatus: 0 | 1 | 2 | 3;
  toStatus: 0 | 1 | 2 | 3;
  triggeredBy: UserResponse;
  paymentId: string;
};
export type PaymentCandidatesResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  human: HumanResponse;
  processDate?: string;
  approvedDate?: string;
  rejectedDate?: string;
  errorDate?: string;
  note?: string;
};
export type PaymentResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  type: string;
  status: 0 | 1 | 2 | 3;
  statusChanges: PaymentStatusChangesResponse[];
  startedBy: UserResponse;
  candidates: PaymentCandidatesResponse[];
  number: number;
  studentsCount: number;
  price: number;
  sum: number;
  period?: string;
};
export type PaymentsCandidatesFiltersRequest = {
  search?: string;
  educationalOrganizations?: string[];
  processResults?: (0 | 1 | 2 | 3)[];
};
export type ApplicationsFilterRequest = {
  search?: string;
  type?: string;
  status?: string[];
  createdAt?: string;
};
export type ApplicationRejectRequest = {
  reason: string;
};
export type HumanNotificationResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  text: string;
};
export type HumanSignupRequest = {
  email: string;
  successUrl?: string;
  deviceId?: string;
  platform?: string;
};
export type HumanLoginResponse = {
  authToken: string;
};
export type HumanLoginRequest = {
  hash: string;
};
export type HumanLoginEmailCodeRequest = {
  code: string;
  email: string;
};
export type HumanRefreshTokenRequest = {
  authToken: string;
};
export type HumanLogoutRequest = {
  deviceId?: string;
  platform?: string;
};
export type TicketFilterRequest = {
  search?: string;
  userId?: string;
  status?: number;
  createdAt?: string;
};
export type TicketResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  subject: string;
  text: string;
  resolved: boolean;
  answerText?: string;
  resolvedBy?: UserResponse;
  resolvedDate?: string;
  human?: HumanResponse;
  humanName?: string;
  humanEmail?: string;
};
export type TicketAnswerRequest = {
  answerText: string;
};
export type PartnerCityResponse = {
  id: string;
  name: string;
  code: string;
};
export type PartnerCategoryResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  code: string;
};
export type PartnerDepartmentResponse = {
  id: string;
  name: string;
  address: string;
  phone: string;
  operatingMode: string;
  latitude: string;
  longitude: string;
  city?: PartnerCityResponse;
};
export type PartnerProgramResponse = {
  id: string;
  name: string;
  category: string;
  banner: string;
  startDate: string;
  endDate: string;
  descriptionFull: string;
  descriptionShort: string;
  site: string;
  isActive: boolean;
};
export type PartnerResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  categories: PartnerCategoryResponse[];
  departments: PartnerDepartmentResponse[];
  programs: PartnerProgramResponse[];
  description: string;
  discount: string;
  isAnchor: boolean;
  isDraft: boolean;
  logo?: MediaFileResponse;
  logoUrl?: string;
  nsiName: string;
  nsiPhone: string;
  nsiEmail: string;
  nsiWebsite: string;
  nsiDescription: string;
};
export type PartnerUpdateRequest = {
  name: string;
  description: string;
  discount: string;
  isAnchor: boolean;
  isDraft: boolean;
  categoryIds: string[];
};
export type PartnerUpdateLogoRequest = {
  file: Blob;
};
export type PartnerShortResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  categories?: PartnerCategoryResponse[];
  departments: PartnerDepartmentResponse[];
  isAnchor: boolean;
  isDraft: boolean;
  status: string;
  discount: string;
  description: string;
  logo?: MediaFileResponse;
};
export type PartnerFilterRequest = {
  search?: string;
  status?: "new" | "hidden" | "published";
  isAnchor?: boolean;
  cityId?: string;
  categoryIds?: string[];
};
export type NsiOperationType = "retrieve" | "insert" | "update" | "delete";
export type NsiUpdateChunkStatus = 0 | 1 | 2 | 3 | 4;
export type SyncDataChunkResponse = {
  id: string;
  operationType: NsiOperationType;
  entityName: string;
  createdAt: string;
  rawData: string;
  statusText?: string;
  status: NsiUpdateChunkStatus;
};
export type SyncDataResponseDto = {
  id: string;
  createdAt: string;
  processDate?: string;
  operationType: NsiOperationType;
  messageId?: string;
  rawData: object;
  statusText?: string;
  chunks?: SyncDataChunkResponse[] | null;
};
export type SyncDataFilterRequestDto = {
  dateFrom?: string;
  dateTo?: string;
  operationType?: NsiOperationType[];
  search?: string;
};
export const {
  useAuthControllerLoginMutation,
  useAuthControllerRefreshMutation,
  useAuthControllerLogin2FaMutation,
  useMetricsControllerIndexQuery,
  useUsersControllerFilterUsersMutation,
  useUsersControllerGetCurrentUserQuery,
  useUsersControllerUpdateCurrentUserMutation,
  useUsersControllerGetUserQuery,
  useUsersControllerUpdateUserMutation,
  useUsersControllerDeleteUserMutation,
  useUsersControllerCreateUserMutation,
  useUsersControllerUpdateUserRoleMutation,
  useUsersControllerUpdateUserActiveStatusMutation,
  useUsersControllerChangeCurrentUserPasswordMutation,
  useUsersControllerResetPasswordMutation,
  useUsersControllerUpdateCurrentUserPhotoMutation,
  useNotificationsControllerFilterMutation,
  useNotificationsControllerGetByIdQuery,
  useNotificationsControllerUpdateMutation,
  useNotificationsControllerDeleteMutation,
  useNotificationsControllerCreateMutation,
  useNotificationsControllerSendEmailMutation,
  useSettingsControllerGetQuery,
  useSettingsControllerUpdateMutation,
  useFilesControllerGetQuery,
  useFilesControllerDeleteFileMutation,
  useFilesControllerEditFileDescriptionsMutation,
  useFilesControllerUploadFileMutation,
  useResourcesControllerLanguagesQuery,
  useResourcesControllerCreateOrUpdateLanguageMutation,
  useResourcesControllerGetTranslationsQuery,
  useResourcesControllerCreateOrUpdateTranslationMutation,
  useResourcesControllerUserRolesQuery,
  useResourcesControllerTestI18Query,
  useResourcesControllerGetEskIssuingQuery,
  useResourcesControllerGetPublicSettingsQuery,
  useResourcesControllerServiceWorksQuery,
  useResourcesControllerGetEduOrgsQuery,
  useNewsTagsControllerGetNewsTagsQuery,
  useNewsTagsControllerCreateNewsTagMutation,
  useNewsTagsControllerUpdateNewsTagMutation,
  useNewsTagsControllerDeleteNewsTagMutation,
  useAuthorsControllerGetAuthorsQuery,
  useAuthorsControllerCreateAuthorMutation,
  useAuthorsControllerUpdateAuthorMutation,
  useAuthorsControllerDeleteAuthorMutation,
  useNewsControllerFilterNewsMutation,
  useNewsControllerGetNewsQuery,
  useNewsControllerUpdateNewsMutation,
  useNewsControllerDeleteNewsMutation,
  useNewsControllerGetNewsByUrlQuery,
  useNewsControllerCreateNewsMutation,
  useEventsControllerFilterEventsMutation,
  useEventsControllerGetEventQuery,
  useEventsControllerUpdateEventMutation,
  useEventsControllerDeleteEventMutation,
  useEventsControllerGetEventBuUrlQuery,
  useEventsControllerCreateEventMutation,
  useInfoPagesControllerFilterInfoPagesMutation,
  useInfoPagesControllerGetInfoPageQuery,
  useInfoPagesControllerUpdateInfoPageMutation,
  useInfoPagesControllerDeleteInfoPageMutation,
  useInfoPagesControllerGetInfoPageBuUrlQuery,
  useInfoPagesControllerCreateInfoPageMutation,
  useParticipantsControllerGetCurrentHumanQuery,
  useParticipantsControllerPutCurrentHumanMutation,
  useParticipantsControllerPutCurrentHumanIdentityMutation,
  useParticipantsControllerGetEskCardQuery,
  useParticipantsControllerGetWorkPlanQuery,
  useParticipantsControllerGetPayHistoryQuery,
  useParticipantsControllerAddHumanFcmTokenMutation,
  useParticipantsHumansControllerFilterHumansMutation,
  useParticipantsHumansControllerGetHumanQuery,
  useParticipantsHumansControllerGetHumanProgramsQuery,
  useParticipantsHumansControllerGetHumanEskCardQuery,
  useParticipantsHumansControllerGetHumanWorkPlanQuery,
  useParticipantsHumansControllerGetHumanPayHistoryQuery,
  useParticipantsHumansControllerGetHumanLogsQuery,
  useParticipantsHumansControllerUndeleteHumanMutation,
  useStudentControllerStudentInfoMutation,
  useStudentControllerApplyStudentMutation,
  useParticipantsApplicationsControllerCreateApplicationEskCardMutation,
  useParticipantsApplicationsControllerCreateApplicationExtraGrantMutation,
  useParticipantsApplicationsControllerCreateApplicationCreditGrantMutation,
  useParticipantsApplicationsControllerCancelApplicationMutation,
  useParticipantsApplicationsControllerGetHumanApplicationsQuery,
  useParticipantsTicketControllerCreateTicketMutation,
  useParticipantsTicketControllerCreateParticipantsTicketMutation,
  useParticipantsTicketControllerGetTicketsQuery,
  useEducationalOrganizationControllerGetEduOrgGrantsQuery,
  useEducationalOrganizationControllerSetEduOrgGrantMutation,
  usePaymentControllerGetAllEduOrgsQuery,
  usePaymentControllerCreatePaymentMutation,
  usePaymentControllerFilterPaymentsMutation,
  usePaymentControllerDownloadPaymentReportQuery,
  usePaymentControllerGetPaymentQuery,
  usePaymentControllerChangeStatusMutation,
  usePaymentControllerDeletePaymentMutation,
  usePaymentControllerGetEducationalOrganizationsQuery,
  usePaymentControllerFilterCandidatesMutation,
  usePaymentControllerDownloadCsvReportQuery,
  usePaymentControllerDownloadEduOrgReportQuery,
  useApplicationControllerFilterApplicationsMutation,
  useApplicationControllerGetApplicationQuery,
  useApplicationControllerRejectApplicationMutation,
  useApplicationControllerApproveApplicationMutation,
  useHumanNotificationsControllerGetMyQuery,
  useAuthEmailControllerPostEmailMutation,
  useAuthEmailControllerPostTokenMutation,
  useAuthEmailControllerLoginByEmailCodeMutation,
  useAuthEmailControllerRefreshTokenMutation,
  useAuthEmailControllerLogoutMutation,
  useAuthEmailControllerDeleteCurrentUserMutation,
  useTicketControllerFilterMutation,
  useTicketControllerGetByIdQuery,
  useTicketControllerAnswerTicketMutation,
  useTicketControllerCreateTicketMutation,
  usePartnerControllerSyncAllQuery,
  usePartnerControllerGetCitiesQuery,
  usePartnerControllerGetCategoriesQuery,
  usePartnerControllerGetPartnerQuery,
  usePartnerControllerUpdatePartnerMutation,
  usePartnerControllerUploadFileMutation,
  usePartnerControllerFilterPartnersMutation,
  useNsiSyncControllerWebhookMutation,
  useNsiSyncControllerFilterSyncDataLogsMutation,
  useNsiSyncControllerGetSyncDataLogQuery,
} = injectedRtkApi;
