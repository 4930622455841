import { FC, useCallback, useMemo, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { usePageTitle } from '@hooks/usePageTitle';
import { useParams, useNavigate } from 'react-router';
import { useGetInfoPageById } from '@area/infoPages/hooks/useGetInfoPageById';
import { useUpdateInfoPage } from '@area/infoPages/hooks/useUpdateInfoPage';
import { EditForm } from '@area/infoPages/components/EditForm';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { FormInfoPageValues, defaultInfoPageValues } from '@area/infoPages/defaultValues';
import { serialize, deserialize } from '@area/redactor/components/Wysiwyg';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { TypeService } from '@services/TypeService';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { getMetaTagsFromValues } from '@area/redactor/components/MetaTags/getMetaTagsFromValues';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';
import styles from './style.module.scss';

export const EditInfoPage: FC = () => {
  usePageTitle('Информационная страница');

  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isError, isLoading } = useGetInfoPageById(id!);
  const {
    updateInfoPage,
    resultOfUpdate: { isSuccess, isLoading: isLoadingForm, error: updateError },
  } = useUpdateInfoPage(id!);
  const { handlePushItem } = useNotifications();

  const { uploadMediaFiles, uploadError, deleteError } = useUploadMediaFiles();

  const onSubmit = useCallback(
    async (values: FormInfoPageValues) => {
      const infoPage = await updateInfoPage({
        ...values,
        editorContent: serialize(values.editorContent as CustomDescendant[]),
      });
      uploadMediaFiles({
        subjectId: infoPage.id,
        snippetPhoto: values.snippetPhoto,
        photoGallery: values.photoGallery,
      });
    },
    [updateInfoPage],
  );

  const defaultValues = useMemo(() => {
    if (data) {
      return {
        ...data,
        editorContent: TypeService.isString(data.editorContent)
          ? deserialize(data.editorContent)
          : defaultInfoPageValues.editorContent,
        metaTags: getMetaTagsFromValues(data.metaTags),
        embedded: data.embedded || '',
      };
    }

    return {
      ...defaultInfoPageValues,
      editorContent: TypeService.isString(defaultInfoPageValues.editorContent)
        ? deserialize(defaultInfoPageValues.editorContent)
        : defaultInfoPageValues.editorContent,
      metaTags: getMetaTagsFromValues(),
    };
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      handlePushItem({ severity: 'success', text: 'Информационная страница обновлена' });
      navigate(buildRoutePath(Routes.informationPages, { absolute: true }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (updateError && 'message' in updateError) {
      handlePushItem({ severity: 'error', text: updateError.message });
    }

    if (uploadError && 'message' in uploadError) {
      handlePushItem({ severity: 'error', text: uploadError.message });
    }

    if (deleteError && 'message' in deleteError) {
      handlePushItem({ severity: 'error', text: deleteError.message });
    }
  }, [updateError, handlePushItem]);

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Информационная страница"
          backBtnTitle="Назад к списку информационных страниц"
          backBtnRoute={buildRoutePath(Routes.informationPages, { absolute: true })}
        />
      </Grid>
      {isLoading && <LoadingSpinner />}
      {isError && <Typography>Ошибка загрузки информационной страницы</Typography>}
      {!isLoading && !isError && (
        <EditForm<FormInfoPageValues> defaultValues={defaultValues} onSubmit={onSubmit} isLoading={isLoadingForm} />
      )}
    </Grid>
  );
};
