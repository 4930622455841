import { Chapter } from '@layouts/Base/components/Sidebar/types';
import { PaymentType } from '@area/payments/types';
import { UserRole } from '@area/users/roles';
import { Routes } from './routes';

export const navigation: Chapter[] = [
  {
    iconName: 'settings',
    title: 'Управление ИС',
    roles: [UserRole.SuperUser, UserRole.Admin, UserRole.Operator],
    links: [
      {
        title: 'Пользователи системы',
        href: Routes.users,
        roles: [UserRole.SuperUser, UserRole.Admin],
      },
      {
        title: 'Переводы',
        href: Routes.translations,
        roles: [UserRole.SuperUser, UserRole.Admin],
      },
      {
        title: 'Настройки',
        href: Routes.settings,
        roles: [UserRole.SuperUser, UserRole.Admin],
      },
      {
        title: 'Стипендии',
        href: Routes.scholarships,
        roles: [UserRole.SuperUser, UserRole.Admin],
      },
      {
        title: 'НСИ Логи',
        href: Routes.nsiLogs,
        roles: [UserRole.SuperUser, UserRole.Admin, UserRole.Operator],
      },
    ],
  },
  {
    iconName: 'news',
    title: 'Контент',
    links: [
      { title: 'Публикации', href: Routes.publications },
      { title: 'Мероприятия', href: Routes.events },
      { title: 'Инфо страницы', href: Routes.informationPages },
      { title: 'Рассылки и уведомления', href: Routes.notifications },
      { title: 'Партнеры', href: Routes.partners },
    ],
  },
  {
    iconName: 'bubble',
    title: 'Заявки',
    roles: [UserRole.SuperUser, UserRole.Admin, UserRole.Operator],
    link: Routes.applications,
  },
  {
    iconName: 'persons',
    title: 'Участники',
    roles: [UserRole.SuperUser, UserRole.Admin, UserRole.Operator],
    link: Routes.students,
  },
  {
    iconName: 'education',
    title: 'Программы',
    roles: [UserRole.SuperUser, UserRole.Admin, UserRole.Operator],
    links: [
      { title: 'Выплата стипендий', href: `${Routes.payments}/${PaymentType.extraGrant}` },
      { title: 'Выплата компенсаций', href: `${Routes.payments}/${PaymentType.creditGrant}` },
    ],
  },
  {
    iconName: 'email',
    title: 'Обращения',
    roles: [UserRole.SuperUser, UserRole.Admin, UserRole.Operator],
    link: Routes.tickets,
  },
];
