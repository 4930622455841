import { useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import indents from '@styles/indents.module.scss';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { AppCard } from '@area/applications/AppCard';
import { useGetApplicationById } from '@area/applications/hooks/useGetApplicationById';
import { LoanDetails } from '@area/applications/LoanDetails';
import { LoadingSpinner } from '@components/LoadingSpinner';

export const ApplicationLoan = () => {
  const [isCreditDataValid, setCreditDataValid] = useState(false);
  const { id } = useParams();
  const { data } = useGetApplicationById(id!);

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item container flexDirection="column" gap={indents.xxs}>
        <Header
          title="Заявка на компенсацию образовательного кредита"
          backBtnTitle="Назад к списку заявок"
          backBtnRoute={buildRoutePath(Routes.applications, { absolute: true })}
        />
      </Grid>
      <Grid item>
        {data ? (
          <>
            <AppCard data={data} isCreditDataValid={isCreditDataValid} />
            {(data.credit || data.creditFiles) && (
              <LoanDetails
                credit={data.credit}
                creditFiles={data.creditFiles}
                status={data.status}
                setCreditDataValid={setCreditDataValid}
              />
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Grid>
    </Grid>
  );
};
