import { HumansFilterRequest, HumanShortResponse } from '@api/admin/adminGeneratedApi';
import { useHumans } from '@area/studentsPage/hooks/useHumans';
import { StudentsFilter } from '@area/studentsPage/StudentsFilter';
import { Icon } from '@components/Icon';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParams } from '@hooks/useQueryParams';
import { Header } from '@layouts/Base/components/Header';
import { Box, Button, Grid, Paper } from '@mui/material';
import colors from '@styles/colors.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutlet } from 'react-router';
import { FilterFormValues, DEFAULT_STUDENTS_SIZE } from '@area/studentsPage/defaultValues';
import { getFilterParamsFromStatusSelectValue } from '@area/studentsPage/utils/getFilterParamsFromStatusSelectValue';
import { DateService } from '@services/DateService';
import styles from './styles.module.scss';

export const StudentsPage = () => {
  usePageTitle('Участники программ');
  const outlet = useOutlet();
  const navigate = useNavigate();

  const goToStudent = (row: HumanShortResponse) => navigate(row.id);

  const { getHumans, humansList, isSuccess, paginate, isLoading, originalArgs, getValuesFromParams } = useHumans();

  const [data, setData] = useState<HumanShortResponse[]>([]);
  const { params } = useQueryParams();

  const fetchData = (values: Partial<HumansFilterRequest>, skip: number, size = DEFAULT_STUDENTS_SIZE) => {
    getHumans({
      skip,
      size,
      asc: false,
      humansFilterRequest: {
        search: values.search,
        isActive: values.isActive,
        isStudent: values.isStudent,
        programType: values.programType,
        registrationDateFrom: values.registrationDateFrom ? values.registrationDateFrom : undefined,
        registrationDateTo: values.registrationDateTo ? values.registrationDateTo : undefined,
        unconfirmedByEduOrgs: values.unconfirmedByEduOrgs,
        eduOrgs: values.eduOrgs,
      },
    });
  };

  const onChange = (values: FilterFormValues) => {
    setData([]);

    const valuesFromStatus = getFilterParamsFromStatusSelectValue(values.status?.value);
    const val = {
      registrationDateFrom: values.registrationDateFrom
        ? DateService.getUTCDate(values.registrationDateFrom)
        : undefined,
      registrationDateTo: values.registrationDateTo ? DateService.getUTCDate(values.registrationDateTo) : undefined,
      search: values.search,
      unconfirmedByEduOrgs: values.unconfirmedByEduOrgs?.map(({ value }) => value),
      eduOrgs: values.eduOrgs?.map(({ value }) => value),
      ...valuesFromStatus,
    };

    fetchData(val, 0);
  };

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(humansList || [])]);
    }
  }, [isSuccess]);

  const loadMore = () => {
    fetchData(originalArgs?.humansFilterRequest!, data.length);
  };

  const columns: Column<HumanShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '72px',
        render: (value: boolean) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
            </Box>
          );
        },
      },
      {
        title: 'Студент',
        align: 'center',
        dataIndex: 'isStudent',
        key: 'isStudent',
        width: '72px',
        render: (value: boolean, record: HumanShortResponse) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Icon
              name={record?.isStudent ? 'check' : 'close'}
              htmlColor={record?.isStudent ? colors.success : colors.error}
            />
          </Box>
        ),
      },
      {
        title: 'ВУЗ',
        dataIndex: 'educationalOrganizationShortName',
        key: 'educationalOrganizationShortName',
      },
      //   {
      //     title: 'Преподаватель',
      //     align: 'center',
      //     dataIndex: 'isTeacher',
      //     key: 'isTeacher',
      //     width: '72px',
      //     render: (value: boolean) => (
      //       <Box display="flex" alignItems="center" justifyContent="center">
      //         <Icon name={value ? 'check' : 'close'} htmlColor={value ? colors.success : colors.error} />
      //       </Box>
      //     ),
      //   },
      {
        title: 'ФИО',
        dataIndex: 'firstName',
        key: 'firstName',
        width: '300px',
        render: (value: boolean, record: HumanShortResponse) => {
          return [record.firstName, record.middleName, record.lastName].filter((w) => !!w).join(' ');
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '72px',
      },
      {
        title: 'СНИЛС',
        dataIndex: 'snils',
        key: 'snils',
        sx: { whiteSpace: 'normal' },
      },
      {
        title: 'Дата регистрации',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (value: string) => DateService.getLocalDate(value, DateService.dateFormats.dateField),
      },
    ],
    [],
  );

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Grid container direction="column" gap="24px">
        <Grid item>
          <Header title="Участники программ" />
        </Grid>
        <Grid item>
          <StudentsFilter onChange={onChange} />
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <>
            <span className={styles.searchResult}>Найдено участников: </span>
            <span className={styles.totalCount}>{paginate?.totalCount ?? 0}</span>
          </>
          <Paper className={styles.paper}>
            <Table<HumanShortResponse> columns={columns} data={data} onRowClick={goToStudent} loading={isLoading} />
            {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
              <Button
                variant="outlined"
                className={styles.loadMoreBtn}
                onClick={loadMore}
                startIcon={<Icon name="more" />}
                disabled={isLoading}>
                Загрузить еще
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
