import { HumanResponse } from '@api/admin/adminGeneratedApi';
import { ReactNode } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { Icon } from '@components/Icon';
import { Passport } from '@area/student/components/Passport';
import { DateService } from '@services/DateService';
import indents from '@styles/indents.module.scss';
import colors from '@styles/colors.module.scss';
import { DeleteStatusHumanSwitch } from '@area/users/components/EditUserForm/DeleteStatusHumanSwitch';
import styles from './styles.module.scss';

export interface PersonalDataProps {
  data: HumanResponse; // todo: replace
}

const fieldOptions: {
  field: Partial<keyof HumanResponse | string>;
  title: string;
  formatter?: (value: any, record: HumanResponse) => ReactNode;
}[] = [
  {
    field: 'birthDate',
    title: 'Дата рождения',
    formatter: (value) => DateService.getLocalDate(new Date(value)),
  },
  {
    field: 'sex',
    title: 'Пол',
    formatter: (value) => (value ? (value === 1 ? 'Мужской' : 'Женский') : undefined),
  },
  {
    field: 'citizenship',
    title: 'Гражданство',
    formatter: (_, record) => record.citizenship?.fullName,
  },
  {
    field: 'address',
    title: 'Адрес',
  },
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'snils',
    title: 'СНИЛС',
  },
  {
    field: 'neededHostel',
    title: 'Нуждается в общежитии',
    formatter: (value) => <Icon name={value ? 'check' : 'close'} color={value ? colors.success : colors.red} />,
  },
  {
    field: 'student',
    title: 'Студент',
    formatter: (_, record) => (
      <Icon name={record?.student ? 'check' : 'close'} color={record?.student ? colors.success : colors.red} />
    ),
  },
  {
    field: 'isTeacher',
    title: 'Преподаватель',
    formatter: (value) => <Icon name={value ? 'check' : 'close'} color={value ? colors.success : colors.red} />,
  },
  {
    field: 'isAgreement',
    title: 'Дал согласие на обработку персональных данных',
    formatter: (value) => <Icon name={value ? 'check' : 'close'} color={value ? colors.success : colors.red} />,
  },
  {
    field: 'createdAt',
    title: 'Дата регистрации',
    formatter: (value) => DateService.getLocalDate(new Date(value)),
  },
  {
    field: 'externalId',
    title: 'Внешний humanID',
  },
];

export const PersonalData = ({ data }: PersonalDataProps) => {
  return (
    <Grid display="flex" flexDirection="row" alignItems="flex-start" gap={indents.l}>
      <Stack flex={1} divider={<Divider className={styles.divider} />}>
        <Box display="flex" alignItems="center" gap={indents.xxs}>
          <Switch checked={data.isActive} />
          <Typography variant="body2">Активный</Typography>
          <DeleteStatusHumanSwitch userId={data.id} isActive={!!data.deletedAt} disabled={!data.deletedAt} />
        </Box>
        {fieldOptions.map(({ field, title, formatter }) => {
          if (!(field in data)) return null;
          const dataValue = data[field as keyof HumanResponse];

          return (
            <Box key={field} className={styles.row}>
              <Typography className={styles.rowTitle}>{title}:</Typography>
              <Typography variant="body2" color={dataValue ? colors.black : colors.grey}>
                {dataValue !== undefined ? (formatter ? formatter(dataValue, data) : (dataValue as any)) : 'Не указан'}
              </Typography>
            </Box>
          );
        })}
      </Stack>
      <Grid item flex={1} className={styles.right}>
        <Icon name="logoShort" viewBox="0 0 32 60" color={colors.red} className={styles.rightIcon} />
        <Typography variant="h3" className={styles.rightTitle}>
          Удостоверение личности
        </Typography>
        {data.identityCards?.map((item) => (
          <Passport key={item.id} data={item} />
        ))}
      </Grid>
    </Grid>
  );
};
