import {
  ParticipantsHumansControllerFilterHumansApiArg,
  useParticipantsHumansControllerFilterHumansMutation,
  HumansFilterRequest,
} from '@api/admin/adminGeneratedApi';
import { DEFAULT_STUDENTS_SIZE, StatusSelectValue } from '@area/studentsPage/defaultValues';
import { DateService } from '@services/DateService';
import { getFilterParamsFromStatusSelectValue } from '../utils/getFilterParamsFromStatusSelectValue';

export const useHumans = () => {
  const query: ParticipantsHumansControllerFilterHumansApiArg = {
    size: DEFAULT_STUDENTS_SIZE,
    humansFilterRequest: {},
  };
  const [getHumans, res] = useParticipantsHumansControllerFilterHumansMutation();

  const handleRequest = (
    args?: Omit<ParticipantsHumansControllerFilterHumansApiArg, 'humansFilterRequest'> & {
      humansFilterRequest: Partial<HumansFilterRequest>;
    },
  ) => {
    const request: ParticipantsHumansControllerFilterHumansApiArg = {
      ...query,
      ...args,
      humansFilterRequest: {
        ...args?.humansFilterRequest,
      },
    };

    getHumans(request);
  };

  const getValuesFromParams = (params: { [key: string]: string }) => {
    const valuesFromStatus = getFilterParamsFromStatusSelectValue(params.status as StatusSelectValue);

    return {
      ...valuesFromStatus,
      search: params.search,
      registrationDateFrom: params.registrationDateFrom
        ? DateService.getDateFromParams(params.registrationDateFrom)
        : undefined,
      registrationDateTo: params.registrationDateTo
        ? DateService.getDateFromParams(params.registrationDateTo)
        : undefined,
      unconfirmedByEduOrgs: params.unconfirmedByEduOrgs
        ? decodeURIComponent(params.unconfirmedByEduOrgs).split(',')
        : undefined,
      eduOrgs: params.eduOrgs ? decodeURIComponent(params.eduOrgs).split(',') : undefined,
    };
  };

  return {
    getHumans: handleRequest,
    humansList: res.data?.data,
    paginate: res.data?.paginate,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess,
    originalArgs: res.originalArgs,
    getValuesFromParams,
  };
};
