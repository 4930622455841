import { useState, useRef, forwardRef, useMemo, KeyboardEvent, useEffect, MouseEvent } from 'react';
import { Box, MenuItem, Menu, Typography, ThemeProvider, Tooltip, Button } from '@mui/material';
import cn from 'classnames';
import { Icon } from '../Icon';
import { Input } from '../Input';
import { Checkbox } from '../Checkbox';
import { SelectedOption, SelectProps } from './types';
import { innerTheme } from './theme';
import { SelectPlaceholder } from './SelectPlaceholder';
import { SelectSelected } from './SelectSelected';
import styles from './style.module.scss';

export const Select = forwardRef((props: SelectProps, _) => {
  const [selected, setSelected] = useState<SelectedOption[]>([]);
  const [inputValue, setInputValue] = useState('');

  const options = useMemo(() => {
    if (!props.options) {
      return [];
    }

    if (!inputValue) {
      return props.options;
    }

    const regExp = new RegExp(inputValue.toLowerCase());
    return props.options.filter(({ label }) => regExp.test(label.toLowerCase()));
  }, [inputValue, props.options]);

  const anchorRef = useRef<Element | null>(null);
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    if (props.disabled) return;
    setOpen(Boolean(anchorRef.current) && true);
    props.onOpen?.();
  };
  const onClose = () => {
    setOpen(false);
    props.onClose?.();
  };
  const onSelect = (option: SelectedOption, isSelected: boolean) => {
    if (props.multiple) {
      const newSelected = isSelected ? selected.filter((i) => i.value !== option.value) : [...selected, option];
      setSelected(newSelected);
      props.onChange?.(newSelected);
      return;
    }

    setSelected([option]);
    setInputValue(option.label);
    props.onChange?.(option);
    onClose();
  };
  const onEnterPress = ({ code }: KeyboardEvent<HTMLInputElement>) => {
    if ((code !== 'Enter' && code !== 'NumpadEnter') || !inputValue) return;

    const regExp = new RegExp(inputValue.toLowerCase());
    const opt = options.find(({ label }) => regExp.test(label.toLowerCase()));

    if (!opt) {
      onSelect({ label: inputValue, value: '' }, false);
    } else {
      const isSelected = selected.findIndex((selectedItem) => selectedItem.value === opt.value) > -1;
      onSelect(opt, isSelected);
    }

    onClose();
  };

  const onClickCreateNewOption = async () => {
    if (!props.onClickAddNewIfNotMatch) {
      return;
    }
    if (!inputValue) {
      return;
    }
    const newOption = await props.onClickAddNewIfNotMatch(inputValue);
    props.onChange?.(props.multiple ? [...selected, newOption] : newOption);
  };

  const onRemoveOption = (remove: (id: string) => void, value: string) => async (e: MouseEvent<SVGSVGElement>) => {
    if (!props.multiple) {
      return;
    }

    e.stopPropagation();
    await remove(value);
    props.onChange?.((props.value as SelectedOption[]).filter((option) => option.value !== value));
    setSelected([...selected].filter((option) => option.value !== value));
  };

  useEffect(() => {
    if (!props.value) {
      setSelected([]);
    } else {
      setSelected(Array.isArray(props.value) ? props.value : [props.value]);
    }
  }, [props.value]);

  return (
    <ThemeProvider theme={innerTheme}>
      <Box className={cn(styles.root, props.className)} sx={props.sx} title={props.title}>
        <Tooltip open={Boolean(props.error) && !open} title={props.error} arrow placement="bottom-start">
          <Box
            ref={anchorRef}
            className={cn(
              styles.input,
              open && styles.focus,
              props.error && styles.errorInput,
              props.disabled && styles.disabledInput,
            )}
            onClick={onOpen}
            onMouseDown={props.onMouseDown}>
            {props.icon && <Box sx={{ marginRight: '8px', height: 24 }}>{props.icon}</Box>}
            <Box sx={{ flex: 1, overflow: 'hidden' }}>
              {!selected.length ? (
                <SelectPlaceholder
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  open={open}
                  multiple={props.multiple ?? false}
                  placeholder={props.placeholder ?? ''}
                  onEnterPress={onEnterPress}
                />
              ) : (
                <SelectSelected
                  disabled={props.disabled ?? false}
                  open={open}
                  multiple={props.multiple ?? false}
                  option={selected}
                  onSelect={onSelect}
                />
              )}
            </Box>
            <Icon name="chevronDown" className={cn(styles.arrow, props.arrowClassName, open && styles.open)} />
          </Box>
        </Tooltip>
      </Box>
      <Menu
        disablePortal
        disableAutoFocus
        disableAutoFocusItem
        open={open}
        anchorEl={anchorRef.current}
        PaperProps={{ style: { width: anchorRef.current?.clientWidth } }}
        onClose={onClose}
        onTransitionEnd={() => {
          if (!open) setInputValue('');
        }}>
        {selected.length && props.multiple ? (
          <Box className={styles.listInput}>
            <Input
              value={inputValue}
              sx={{ width: '100%' }}
              onChange={({ target }) => setInputValue(target.value)}
              onKeyDown={onEnterPress}
              placeholder="Поиск"
            />
          </Box>
        ) : null}
        {!options.length &&
          (!!props.onClickAddNewIfNotMatch && !!inputValue ? (
            <Box className={styles.emptyList}>
              {props.addNewIfNotMatchLabel
                ? props.addNewIfNotMatchLabel(inputValue)
                : `Список пуст, добавить новый вариант "${inputValue}"?`}
              <Button variant="text" size="small" onClick={onClickCreateNewOption}>
                Да
              </Button>
            </Box>
          ) : (
            <Box className={styles.emptyList}>Список пуст</Box>
          ))}
        {options.map(({ value, label, icon, disabled, remove }) => {
          const isSelected = selected.findIndex((selectedItem) => selectedItem.value === value) > -1;

          return (
            <MenuItem
              disableRipple
              key={value}
              value={value}
              disabled={disabled}
              selected={isSelected}
              onClick={() => onSelect({ value, label }, isSelected)}>
              {props.multiple && <Checkbox sx={{ marginRight: '8px' }} checked={isSelected} />}
              {(icon || props.optionIcon) && (
                <Box component="span" sx={{ marginRight: '8px', display: 'inline-flex' }}>
                  {icon ?? props.optionIcon}
                </Box>
              )}
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
              {remove && <Icon name="delete" onClick={onRemoveOption(remove, value)} className={styles.removeIcon} />}
            </MenuItem>
          );
        })}
      </Menu>
    </ThemeProvider>
  );
});
