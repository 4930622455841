import { EventRequest } from '@api/admin/adminGeneratedApi';
import { Option } from '@components/Select/types';
import colors from '@styles/colors.module.scss';
import { IconName } from '@components/Icon/types';
import dayjs from 'dayjs';
import { CustomDescendant } from '@area/redactor/components/Wysiwyg/types';
import { MediaFileParams } from '@area/publications/defaultValues';
import { KampusEventType } from './types';

export const kampusEventTypes: Option<KampusEventType>[] = [
  { label: 'Лекция', value: 'lecture' },
  { label: 'Party', value: 'party' },
];

export type FormKampusEventValues = Omit<EventRequest, 'type' | 'editorContent' | 'dateStart' | 'dateEnd'> & {
  type: Option<KampusEventType> | string;
  editorContent: CustomDescendant[] | string;
  dateStart: dayjs.Dayjs | string;
  dateEnd: dayjs.Dayjs | string;
  mainImage?: MediaFileParams;
  snippetPhoto?: MediaFileParams;
  photoGallery?: MediaFileParams[];
};

export const defaultKampusEventValues: FormKampusEventValues = {
  published: true,
  important: false,
  dateStart: '',
  dateEnd: '',
  title: '',
  url: '',
  locationName: '',
  locationCoordinates: '',
  type: '',
  editorContent: '',
  metaTags: [],
  embedded: '',
};

export const sharedKampusEventFieldNames = ['photo', 'title'];

export const kampusEventTypeColors: Record<KampusEventType, string> = {
  lecture: colors.greyLight,
  party: colors.lightRed,
};

export const DEFAULT_KAMPUS_EVENTS_SIZE = 10;

export const KAMPUS_EVENTS_ICONS: IconName[] = ['link', 'delete'];
