import { Option } from '@components/Select/types';
import { HumansFilterRequest } from '@api/admin/adminGeneratedApi';

export enum StatusSelectValue {
  ACTIVE = 'active',
  NOT_ACTIVE = 'notActive',
  ACTIVE_STUDENT = 'activeStudent',
  ACTIVE_NOT_STUDENT = 'activeNotStudent',
}

export type FilterFormValues = Partial<
  Omit<HumansFilterRequest, 'isActive' | 'type' | 'unconfirmedByEduOrgs' | 'eduOrgs'> & {
    status: Option<StatusSelectValue>;
    type: string;
    unconfirmedByEduOrgs: Array<Option>;
    eduOrgs: Array<Option>;
  }
>;

export const DEFAULT_STUDENTS_SIZE = 10;
