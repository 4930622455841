import { FC, useMemo } from 'react';
import { HumanResponse } from '@api/admin/adminGeneratedApi';
import { Tabs } from '@components/Tabs';
import { Tab } from '@components/Tabs/types';
import { PersonalData } from '@area/student/components/PersonalData';
import { AcademicPerformance } from '@area/student/components/AcademicPerformance';
import { ESK } from '@area/student/components/ESK';
import { ProgramStatus } from '@area/student/components/ProgramStatus';
import { StudyInfo } from '@area/student/components/StudyInfo';
import { HumanLogs } from '@area/student/components/HumanLogs';
import { Payouts } from '../Payouts';
import styles from './styles.module.scss';

export interface StudentTabsProps {
  human: HumanResponse;
}

export const StudentTabs: FC<StudentTabsProps> = (props) => {
  const { human } = props;

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: 'Личные данные',
        content: <PersonalData data={human} />,
      },
      {
        label: 'Информация об обучении',
        content: <StudyInfo data={human.student} />,
      },
      {
        label: 'Статус по программам',
        content: <ProgramStatus />,
      },
      {
        label: 'Успеваемость',
        content: <AcademicPerformance />,
      },
      {
        label: 'Выплаты',
        content: <Payouts />,
      },
      {
        label: 'ЕСК',
        content: <ESK />,
      },
      { label: 'Логи', content: <HumanLogs /> },
    ],
    [],
  );

  return (
    <Tabs
      name="studentTabs"
      defaultValue={0}
      tabs={tabs}
      tabClassName={styles.tab}
      tabPanelClassName={styles.tabPanel}
      variant="scrollable"
      scrollButtons
    />
  );
};
